<div class="centerText">Your session will expire in</div>
<p></p>
<div class="centerText">
  <font size="20">{{ clockDisplay }}</font>
</div>

<p></p>
<div class="centerText">Click <b>OK</b> to continue your session.</div>
<p></p>
<div class="container">
  <button mat-button-primary [mat-dialog-close]="true">OK</button>
</div>
