import { SpecialEvent } from "./specialevent.model";
import { SpecialEventDetail } from "./specialeventdetail.model";

export class SpecialEventWithDetail {

  specialEvent : SpecialEvent;
  specialEventDetailList : SpecialEventDetail[];

  constructor() {

    this.specialEvent = new SpecialEvent();
    this.specialEventDetailList = new Array<SpecialEventDetail>();
  }

}
