
export class SpecialEventDetail {

  id : string;
  specialEventID : string;
  nonProfitID : string;
  amount : number;
  npTitle : string;

  constructor() {
    this.id ='';
    this.specialEventID ='';
    this.nonProfitID ='';
    this.amount = 0;
    this.npTitle ='';
  }

}
