import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SeccdonateService } from '../../services/seccdonate.service';
import { NonprofitcontributeComponent } from '../nonprofitcontribute/nonprofitcontribute.component';
import { Session } from 'protractor';


@Component({
  selector: 'app-contributions',
  templateUrl: './contributions.component.html',
  styleUrls: ['./contributions.component.css']
})
export class ContributionsComponent implements OnInit {

  private contributerSubscription : Subscription = new Subscription();


  zipCode : string;
  email : string;
  accuracyPledge : string;  //'agree' or 'disagree'
  isAccuracyPledgeReminder : boolean; //show 'agree to continue' reminder message



  //Columns for contributions table
  displayedColumns: string[] = ['code', 'title', 'amount', 'volunteer'];

  //various UI flags
  isUpdating : boolean = false;
  isSaved : boolean = false;
  isContributorUpdated : boolean = false;
  payFactor : number = 1;

  isEmailOrZipEmpty : boolean;

  isInValid : boolean; // final email/zip check before continuing


  contributions : Array<NonprofitcontributeComponent>;

  constructor(public seccdonateService : SeccdonateService,
              private router: Router,
              private sessiontimeoutService : SessiontimeoutService ) { }

  ngOnInit() {

    this.payFactor = this.seccdonateService.payFactor;
    this.sessiontimeoutService.reset();
    console.log(this.seccdonateService.npDonations);
    this.getContributor(this.seccdonateService.employeeId);
    this.displayedColumns = ['code', 'title', 'amount','volunteer'];
    this.contributions = this.seccdonateService.npDonations;

  }

  calcTotal() : number {

    /*
    if(this.contributions == null) return 0;
    let total:number = 0;
    this.contributions.forEach( x => {total = (+total) +  (+x.amount) });
    return total;
    */
    return this.seccdonateService.annualAmount;
  }

  updateAddress() {


  }

  editContributions() {

    this.router.navigate(['contributor/designations']);
  }

  continue() {

    // Check of both email/zip originally present or just updated
    if(this.isEmpty(this.seccdonateService.contributor.email) || this.isEmpty(this.seccdonateService.contributor.zip)) {
      this.isInValid = true;
      return;
    } else {
      this.isInValid = false;
    }


    //Check accuracy pledge (agree/disagree);
    if(this.accuracyPledge != 'agree') {
      this.isAccuracyPledgeReminder = true;
      return ;
    }
    this.isAccuracyPledgeReminder = false;
    this.router.navigate(['contributor/paymentmethod']);
    //Do stuff to continue

  }

  getContributor(ein : string) {

    this.contributerSubscription = this.seccdonateService.getContributor(ein).subscribe(
      result => {
        let apiresponse : any = result;
        this.seccdonateService.contributor = result;
        this.email = apiresponse.email;  // not needed
        this.zipCode = apiresponse.zip;  // not needed

      },
      err => {
      }
    );
  }

  updateContributor() {

    //Check if both fields are populated (no format check)
    if(this.isEmpty(this.email)|| this.isEmpty(this.zipCode)) {
      this.isEmailOrZipEmpty = true;
      return;
    } else {
      this.isEmailOrZipEmpty = false;
    }

    this.isUpdating = true;
    this.contributerSubscription = this.seccdonateService.updateContributor(this.email,this.zipCode).subscribe(

      result => {
        let apiresponse : any = result;
        this.seccdonateService.contributor = result;
        this.contributerSubscription.unsubscribe();
        this.isUpdating = false;
        this.isContributorUpdated = true;
      },
      err => {
        this.isUpdating = false;
      }
  );
  }



  //find contribution item for the given stCode and update volunteer preference (y/n)
  updateVolunteerInfo(code, e) {

    let idx : number = -1;
    idx = this.seccdonateService.npDonations.findIndex( item => item.code == code);
    if(idx > -1 ) {
      if(e.target.checked==true) {
          this.seccdonateService.npDonations[idx].notifyRecipient = true;
      } else {
          this.seccdonateService.npDonations[idx].notifyRecipient = false;
      }
    }
  }


  //Empty string
  private  isEmpty(text: string): boolean {
    return !(text && text.trim().length > 0);
  }



  ngOnDestroy(){

    if(this.contributerSubscription != undefined) this.contributerSubscription.unsubscribe();
  }


}
