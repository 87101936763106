<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  <title>
    State Employees Charitable Giving Campaign
  </title>
</head>

<body>

  <script type="text/javascript" src="Scripts/jquery-1.3.2.min.js"></script>

  <script type="text/javascript" src="Scripts/jquery-ui-1.7.2.custom.min.js"></script>

  <form name="aspnetForm" method="post" action="./receipt.aspx?session_id=101a7829-888d-40bf-ba57-969501bbc169"
    id="aspnetForm">
    <div>
    </div>


    <div>

    </div>


    <div>
      <span id="dirtyForm" display="none"></span>
      <span id="ctl00_Label1"></span>

    </div>
    <div id="wrapper">
      <div id="header">
        <h1><a href="default.aspx">Arizona State Employee Charitable Campaign</a></h1>
        <img id="ctl00_imgHeader" src="assets/img/secc.png" alt="Arizona State Employee Charitable Campaign"
          style="border-width:0px;">
      </div>
      <div id="nav">

        <ul>
          <li>
            <a id="ctl00_LoginView1_LoginStatus1" title="Logout" href="contributor/auth/dologout">LOGOUT</a>
          </li>
          <!--<li><a href="feedback.aspx" title="Feedback">FEEDBACK</a></li>//-->
          <li><a href="assets/doc/Instructions.pdf" title="Instructions" target="_blank">INSTRUCTIONS</a></li>
          <li><a  href="contributor/welcome" title="Home">HOME</a></li>
        </ul>

      </div>
      <div id="container">


        <h2>Contribution Confirmation Receipt <span
            id="ctl00_mainContent_lblParticipantYear">{{lblPartcipantYear}}</span></h2>
        <div *ngIf="! isFromHistory()"  id="ctl00_mainContent_pnlPayroll">
          <!-- TODO {{firstDeductionDate | date : "longDate" }} -->
          <p>If you chose payroll deduction method, the first deduction will occur on <span
              id="ctl00_mainContent_lblFirstDeductionDate"> January 9, 2025</span>.</p>
        </div>
        <h3>{{seccdonateService.contributor.firstName}} {{seccdonateService.contributor.lastName}}, thank you for your
          contribution!</h3>
        <h3>Contribution Summary</h3>
        <!--<p><strong>Date: </strong>{{selectedDesigTrx.createDate | date}}</p>-->
        <p *ngIf="!isFromHistory()"><strong>Date: </strong>{{today | date  }}</p>
        <p *ngIf="isFromHistory()"><strong>Donation Date: </strong>{{ selectedDesigTrx.submitDate | date : "shortDate"}}</p>

        <table mat-table [dataSource]="contributions" cellpadding="3" cellspacing="3" border="0" width="80%">


          <!--  ST Code -->
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef> ST Code </th>
            <td mat-cell *matCellDef="let contribution"> {{contribution.code}} </td>
          </ng-container>


          <!--  Title -->
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Organization Name </th>
            <td mat-cell *matCellDef="let contribution"> {{contribution.nonProfitTitle}} </td>
          </ng-container>

          <!--  Amount -->
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Annual (26 pay periods) Amount</th>
            <td mat-cell *matCellDef="let contribution"> {{getAnnualAmount(contribution)| currency}} </td>
          </ng-container>


          <!--  Volunteer --> <!--TODO set model correctly-->

          <ng-container matColumnDef="volunteer">
            <th mat-header-cell *matHeaderCellDef>Volunteer Info</th>
            <td mat-cell *matCellDef="let contribution">
              <input type="checkbox" mat-checkbox [checked]="contribution.notifyRecipient" [disabled]="true">
            </td>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <!-- END Mat-Table-->
        <p><strong>TOTAL: {{ this.seccdonateService.annualAmount | currency }}</strong></p>

        <!-- End replacement from contributions-->





        Method of payment is <strong>{{getPaymentMethod()}}</strong> <br>
        {{getNote()}}
        <br>No goods and/or services were provided in connection with this contribution.
        <br><br>
        <div id="receiptbuttons">
          <input type="button" onclick="window.print();" value="Print">
          <input type="button" name="edit" value="SECC Home" (click)="goSeccHome()">
        </div>

        <div *ngIf="isReadyToDonateVisible()" id="ctl00_mainContent_pnlStart" style="float: right; padding-top: 20px; padding-right: 40px;">
          <h2>
            I am ready to
            <a id="ctl00_mainContent_hlStart" href="contributor/designations">donate</a>
          </h2>
        </div>
      </div>
      <div id="footer">
        <table width="100%">
          <tbody>
            <tr>
              <td width="47%"><a href="mailto:Linda.Stiles@azdoa.gov" id="ctl00_aDirector"
                  target="_blank">Linda.Stiles@azdoa.gov</a>
              </td>
              <td>
                <a href="default.aspx">SECC Home</a>
              </td>
              <td style="text-align: right; font-weight: bold;">
                Version: 2.0.0
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>


  <div class="ui-dialog ui-widget ui-widget-content ui-corner-all  ui-draggable ui-resizable" tabindex="-1"
    role="dialog" aria-labelledby="ui-dialog-title-ctl00_Timeout1"
    style="display: none; position: absolute; overflow: hidden; z-index: 1000; outline: 0px;">
    <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix" unselectable="on"><span
        class="ui-dialog-title" id="ui-dialog-title-ctl00_Timeout1" unselectable="on">Session Expiring</span><a href="#"
        class="ui-dialog-titlebar-close ui-corner-all" role="button" unselectable="on"><span
          class="ui-icon ui-icon-closethick" unselectable="on">close</span></a></div><span id="ctl00_Timeout1" style=""
      class="ui-dialog-content ui-widget-content">
      <p>
        <span class="ui-icon ui-icon-alert" style="float: left; margin: 1px 10px 20px 0;">
        </span>Your session will expire in.
      </p>
      <span id="tscCounter"></span>&nbsp;seconds
      <p>
        Click <b>OK</b> to continue your session.</p>
    </span>
    <div class="ui-resizable-handle ui-resizable-n" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-e" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-s" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-w" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se ui-icon-grip-diagonal-se"
      unselectable="on" style="z-index: 1001;"></div>
    <div class="ui-resizable-handle ui-resizable-sw" unselectable="on" style="z-index: 1002;"></div>
    <div class="ui-resizable-handle ui-resizable-ne" unselectable="on" style="z-index: 1003;"></div>
    <div class="ui-resizable-handle ui-resizable-nw" unselectable="on" style="z-index: 1004;"></div>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix"><button type="button"
        class="ui-state-default ui-corner-all">Ok</button></div>
  </div>
</body>
