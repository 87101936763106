import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SeccdonateService } from '../../services/seccdonate.service';
import { NonprofitcontributeComponent } from '../nonprofitcontribute/nonprofitcontribute.component';




@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {

  constructor(public seccdonateService : SeccdonateService,
              private router : Router,
              private sessiontimeoutService : SessiontimeoutService
              ) { }

  // Below -- NOT NEEDED ?
  designationTrxHistory : Array<any> ; // Array of past donations (designationTrx), eg over multiple years
  selectedDesigTrx : any = {}; //Selected DesignatedTransaction whose receipt will be displayed


  //Columns for contributions table
  displayedColumns: string[] = ['code', 'title', 'amount', 'volunteer'];
  contributions : Array<NonprofitcontributeComponent>;
  payFactor : number = 1;

  isShowReadyToDonate : boolean = false;
  today : Date;




  //- SECC Campaign Year {0} For Tax Year {1}", designation.ParticipantYear, designation.ParticipantYear + 1);

  lblPartcipantYear : string;  //formatted label
  firstDeductionDate : string;  //raw string


  ngOnInit() {
    this.today = new Date();
    this.sessiontimeoutService.reset();

    this.displayedColumns = ['code', 'title', 'amount','volunteer'];
    this.contributions = this.seccdonateService.npDonations;
    this.payFactor = this.seccdonateService.payFactor;

    this.renderLabels();
    this.designationTrxHistory = this.seccdonateService.designationTrxHistory;
    this.loadTrxDetails();

  }

  getAnnualAmount(contribution : NonprofitcontributeComponent) {

    //When coming from the HISTORY page, the payfactor is factored in the 'amount'.
    //The PayFactor factor is not required in this case. However when submitting
    // a new order it will be required.
    if(this.isFromHistory()) {
      return contribution.amount;
    }

    //For new orders..
    return this.seccdonateService.payFactor * contribution.amount;
  }


  //When coming from History PF is undefined because it's not stored anywhere
  // This is a hack and not the ideal way to check if coming from History
  isFromHistory() : boolean  {
    console.log('isFromHistory: ');
    console.log(this.seccdonateService.payFactor == undefined);
    return this.seccdonateService.payFactor == undefined;
  }



  renderLabels() {

    var participantYear : number = this.seccdonateService.participantYear;
    var yearAfter : number = this.seccdonateService.participantYear + 1;

    this.lblPartcipantYear = "- SECC Campaign Year " + participantYear +
                                      " For Tax Year " + yearAfter;

    this.firstDeductionDate = this.seccdonateService.firstDeductionDate;

  }

  // If any of the donations in history is for the campaign year,
  // turn visibility OFF. Donation already made.
  isReadyToDonateVisible() : boolean {

    if(Object.keys(this.seccdonateService.designationTrxHistory).length === 0) { // If NEW contribution (meaning NOT from History page)
                                                                                 // Note: This is a fancy way of checking if designationTrxHistory
                                                                                 // is an empty object.
                                                                                 // This works but maybe there's a better way.
      return false;
    }

    this.seccdonateService.designationTrxHistory.forEach( c => {
      if( +(c.participantYear) == this.seccdonateService.campaignYear ) {
        return false;
      }
    })
    return true; //default
  }


  loadTrxDetails() {
    var trxId : string = this.seccdonateService.dispReceiptTrx;
    for(var i=0; i < this.designationTrxHistory.length; i++) {
      if(this.designationTrxHistory[i].id == trxId) {
        this.selectedDesigTrx = this.designationTrxHistory[i];
      }
    }

    /* If from HISTORY page   */
    //if(this.contributions.length==0) {  //contributions array is populated in the Contributions page. If it's empty
                                        // then user came to receipt page from the History page and not by making
                                        // actual contributions. In that case populate from selectedDesigTrx above
                                        // which is the transaction year the user clicked on

    //BLOCK Execute ONLY when coming from HISTORY page
    if(this.seccdonateService.isFromHistory) { //Only set (=true) in History page. Default state is false

      this.contributions =  new Array<NonprofitcontributeComponent>() ; //Reset

      this.seccdonateService.annualAmount = this.selectedDesigTrx.total; /* Safe to set annual amt with historical value here because if user chooses to make
                                                                            contribution after viewing history, this will get overwritten in the Designations
                                                                            page.
                                                                          */
      if(this.selectedDesigTrx.designations.length > 0) {
        for( var i = 0; i< this.selectedDesigTrx.designations.length; i++) {


         let np : NonprofitcontributeComponent =
                      new NonprofitcontributeComponent(this.seccdonateService, this.sessiontimeoutService);

          np.code = this.selectedDesigTrx.designations[i].nonProfit.code;
          np.amount = this.selectedDesigTrx.designations[i].amount; //TODO should be ~times payFactor
          np.nonProfitTitle = this.selectedDesigTrx.designations[i].nonProfit.legalName;
          np.notifyRecipient = this.selectedDesigTrx.designations[i].notifyRecipient;
          this.contributions.push(np);
        }
      }
    }

    this.seccdonateService.isFromHistory = false; // Unset flag to default state (false);
  }


  getNote() {

    // 1.
    if(this.selectedDesigTrx.paymentType=='PRD') {  //Payroll
       var desig = this.selectedDesigTrx.designations[0];

       if( desig != null && desig != undefined &&
           desig.ParticipantYear != null &&
           desig.ParticipantYear != undefined )
        {
          var note : string = "Paycheck Deduction Amount (each of 26 pay periods will be reflected in "
                              + (Number(desig.ParticipantYear)+1).toString + " paychecks): $"
                              + (Number(this.selectedDesigTrx.total)/26).toFixed(2);
          return note;

        } else {
          var note : string = " Paycheck Deduction Amount (each of 26 pay periods): $" + (Number(this.selectedDesigTrx.total)/26).toFixed(2);
          return note;
        }
    }


    //2.
    if(this.selectedDesigTrx.paymentType=='EC') {   // Cash
      return "The transaction will be shown as \"Arizona State Employees Charitable Campaign\" on your statement.";
    }

    //3.
    if(this.selectedDesigTrx.paymentType=='CC') {   // Cash
       return "The transaction will be shown as \"Arizona State Employees Charitable Campaign\" on your statement.";
    }

    return ""; //default
  }


  goSeccHome() {
    this.router.navigate(['contributor/welcome']);
  }

  getPaymentMethod() {

    var paymentType = this.seccdonateService.paymentType;
    if (paymentType == null)
      return "Unknown Payment Option";

            if (paymentType == "PRD") //payroll deduction
                return "Automatic Payroll Deduction";
            else if (paymentType == "CA") // cash
                return "Check / Cash";
            else if (paymentType == "EC") // eCheck
                return "eCheck";
            else if (paymentType == "CC") //credit card
                return "Credit / Debit Card";
            else
                return '';
  }

  ngOnDestroy(){

  }

}
