<html xmlns="http://www.w3.org/1999/xhtml">

<head id="ctl00_Head1">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <title>Delete Donation</title>
</head>

<body>
  <form name="aspnetForm" method="post" id="aspnetForm">
    <div></div>

    <div>
      <span id="dirtyForm" display="none"></span>
      <span id="ctl00_Label1"></span>
      <span id="ctl00_Timeout1" title="Session Expiring" style="display: none">
        <p>
          <span class="ui-icon ui-icon-alert" style="float: left; margin: 1px 10px 20px 0">
          </span>Your session will expire in.
        </p>
        <span id="tscCounter"></span>&nbsp;seconds
        <p>Click <b>OK</b> to continue your session.</p>
      </span>
    </div>
    <div id="wrapper">
      <div id="header">
        <img id="ctl00_imgHeader" src="assets/img/header.jpg" alt="SECC Header and Logo"
          style="height: 190px; width: 890px; border-width: 0px" />
      </div>

      <div id="nav">

        <ul>
          <li>
            <a id="ctl00_LoginView1_LoginStatus1" title="Logout" href="admin/auth/dologout">LOGOUT</a>
          </li>

          <li><a id="ctl00_LoginView1_hlHome" title="Home" href="admin/home">HOME</a></li>
        </ul>

      </div>

      <div>

        <table width="100%" border="1">
          <tbody>
            <tr style="background-color:Aqua">
              <td>
                <div id="ctl00_LoginView2_UpdatePanel1">

                  <div style="width: 100%; height: 28px">
                    <div id="ctl00_LoginView2_timer" style="border-style:Solid;">


                      <div style="width: 100%; height: 100%; vertical-align: middle; text-align: center;">
                        <span id="ctl00_LoginView2_currentTime" style="font-size: large; font-weight: bold; ">
                          &nbsp;&nbsp;&nbsp;&nbsp;Payroll Deduction: {{bannerSvc.donationSummaryList[0].prd | currency}}
                          &nbsp;&nbsp;&nbsp;&nbsp;One Time
                          Payments/Special Events: {{bannerSvc.donationSummaryList[0].cc | currency}}</span>
                      </div>


                    </div>
                  </div>

                </div>


              </td>
            </tr>
          </tbody>
        </table>

      </div>

      <div id="container">

        <div>
          <strong>Enter EIN:</strong>
          <input name="ctl00$mainContent$txtEIN" type="text"  id="ctl00_mainContent_txtEIN"
            style="width:100px;"  [(ngModel)]="adminSvc.employeeEin">



          <a><img src="assets/img/find.png" id="ctl00_mainContent_es1_imgFind"
              style="border: none;" alt="Click here to look up an employee id" (click)="showEmpIdLookupModal()"></a>


          <br>
          <input type="button" name="ctl00$mainContent$StartButton" value="Start" id="ctl00_mainContent_StartButton" (click)="getDonationsForDelete()">
          <div id="ctl00_mainContent_Message" class="reqd" >
          </div>
          <div *ngIf="isShowMessage"   id="ctl00_mainContent_Message" class="reqd">
            {{message}}
          </div>
        </div>

      <div *ngIf="isShowDonations">
        <div>
          <h2>
            Contributions for <span style="text-transform: capitalize;">
              <span id="ctl00_mainContent_lblName">{{donationsForDelete.contributor.firstName}} {{donationsForDelete.contributor.lastName}}</span></span>
          </h2>
        </div>

        <table cellpadding="3" cellspacing="3" border="0" width="80%">
          <tbody>
            <tr style="background-color: #fff;">
              <td>
                <strong>Nonprofit<br>
                  Code</strong>
              </td>
              <td>
                <strong>Organization<br>
                  Name</strong>
              </td>
              <td>
                <strong>Annual (26 pay periods)<br>
                  Amount</strong>
              </td>
            </tr>

            <tr *ngFor="let item of donationsForDelete.designations" style="background-color: #fff;"  >
              <td>
                {{item.nonProfit.code}}
              </td>
              <td>
                {{item.nonProfit.legalName}}
              </td>
              <td>
                {{item.amount | currency }}
              </td>
            </tr>

            <tr style="font-weight: bold;">
              <td colspan="2">
                Total:
              </td>
              <td>
                <span id="ctl00_mainContent_lblTotal">{{donationsTotal | currency}}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <p style="text-align: center;">
            <input type="submit" name="ctl00$mainContent$btnDelete" value="Delete Donation"
              (click)="askDeleteConfirm()"
              id="ctl00_mainContent_btnDelete">
          </p>
        </div>

      </div>

      </div>

      <div id="footer">
        <ul>
          <li><b>Version: 2.0.0 </b>:: <a href="/">SECC Home</a></li>
        </ul>
      </div>
    </div>
  </form>

  <span id="okta-plugin-message-channel-available" style="display: none"></span>
</body>

</html>
