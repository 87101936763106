import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-adminlogin',
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./adminlogin.component.css']
})
export class AdminloginComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) { }
  isLoginError : boolean = false;

  ngOnInit(): void {

    const error = this.activatedRoute.snapshot.queryParams['error'];

    if(error=='true') {
      this.isLoginError = true;
    } else {
      this.isLoginError = false;
    }

  }

}
