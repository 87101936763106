import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BannerService } from 'src/app/services/banner.service';

import { SpecialEvent } from 'src/app/models/specialevent.model';
import { AdminService } from 'src/app/services/admin.service';

import { SpecialEventWithDetail } from 'src/app/models/specialeventwithdetail.model';

@Component({
  selector: 'app-listevents',
  templateUrl: './listevents.component.html',
  styleUrls: ['./listevents.component.css']
})
export class ListeventsComponent implements OnInit {

  specialEventsSubscription: Subscription = new Subscription();
  specialEventWithDetailSubscription : Subscription = new Subscription();
  deleteSpecialEventSubscription : Subscription = new Subscription();
  specialEvents: SpecialEvent[] = new Array<SpecialEvent>();

  constructor(public bannerSvc: BannerService,
    private adminSvc: AdminService,
    private router : Router) {

  }




  ngOnInit(): void {

    this.bannerSvc.getDonationSummaryAll();
    this.getSpecialEvents();
  }


  getSpecialEvents() {

    this.specialEventsSubscription = this.adminSvc.getSpecialEvents().subscribe(response => {
      this.specialEvents = response as Array<SpecialEvent>;
      console.log(this.specialEvents);
    }
    ), (err => {
      console.log(err);
    })

  }

  addNewSpecialEvent() {
    this.router.navigate(['admin/specialevents/add']);
  }


  getSpecialEventWithDetail(id : string) {

    this.specialEventWithDetailSubscription = this.adminSvc.getSpecialEventWithDetail(id).subscribe(response => {
      var specialEventWithDetail = response as SpecialEventWithDetail;
      this.adminSvc.selectedSpecialEvent = specialEventWithDetail;
      this.router.navigate(['admin/specialevents/edit']);
    }
    ), (err => {
      console.log(err);
    })
  }


  deleteEvent(seId : string) {
    this.deleteSpecialEventSubscription = this.adminSvc.deleteSpecialEvent(seId).subscribe(response => {
      this.getSpecialEvents();
    }
    ), (err => {
      console.log(err);
    })
  }

  ngOnDestroy() {

    if (this.specialEventsSubscription != undefined) this.specialEventsSubscription.unsubscribe();
    if (this.specialEventWithDetailSubscription != undefined) this.specialEventWithDetailSubscription.unsubscribe();
    if (this.deleteSpecialEventSubscription != undefined) this.deleteSpecialEventSubscription.unsubscribe();


  }


}
