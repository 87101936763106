import { NpDonation } from 'src/app/models/npdonation.model';
export class SpecialEventByEventNum {

  specialEventNo : string;
	agencyTitle : string;
	contactName : string;
	contactPhone : string;
	amount : number;
	eventDate : string;
	participantYear : number;

	donations : NpDonation[];

  constructor() {

    this.donations = new Array<NpDonation>();
  }
}
