
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<title>
	SECC Online Donations
</title></head>

<body>


<form name="aspnetForm" method="post" action="./contributions.aspx" id="aspnetForm">



<script src="/Donate/WebResource.axd?d=JbDTahrdrbkLlH8o_JdgA9ikMXEcF_EJc6Z3OJN6RSNN-yHnEbHAbSAKxANE1xGypJ82kpWTD6VsrrsyxV6Vf9C_FJOG4AJyiBbuX3BUJS01&amp;t=637814401746327080" type="text/javascript"></script>


<script type="text/javascript" src="/Donate/ajaxpro/prototype.ashx"></script>
<script type="text/javascript" src="/Donate/ajaxpro/core.ashx"></script>
<script type="text/javascript" src="/Donate/ajaxpro/converter.ashx"></script>
<script type="text/javascript" src="/Donate/ajaxpro/SECGC.SECGCClient.ContributionsPage,SECGC.SECGCClient.ashx"></script>

<script type="text/javascript">
//<![CDATA[
function CallServer(arg, context){ WebForm_DoCallback('ctl00$Timeout1',arg,ReceiveServerData,context,null,false);}//]]>
</script>

<script src="/Donate/ScriptResource.axd?d=dsj5ACFzcBEGRMZL6hrpaW7FP-xanaiRs0mQ6-JSUvXhX2gbyyaij3ZaTyoI5ZisZTuU8txuqE5eq-r5IkSotnHDh0WeqFM6XQlKO-qdy-bgqc8hFT0MHIc-Kj48AjuNYEGvXvPOVfnTOfkyFrzEWg2&amp;t=ffffffffcda39869" type="text/javascript"></script>
<script type="text/javascript">
//<![CDATA[
if (typeof(Sys) === 'undefined') throw new Error('ASP.NET Ajax client-side framework failed to load.');
//]]>
</script>

<script src="/Donate/ScriptResource.axd?d=jAL1FnEauOs4rW5Ys2pIBxXBQy2P0SkXnHvsHRj1hvOgIqtEIx9vDSrcAQYBN5cYs1YBsK3u60iB96dzyg_rDSXlFG56oI_imNFQR8AITrJSzeLMrEnt6X0_LKorse4HuCsn3BHqY1LJYDh9VOPOkQ2&amp;t=ffffffffcda39869" type="text/javascript"></script>
<script src="/Donate/WebResource.axd?d=r7RIgbZ8ZkVe0Zi-2-FAckt9JkvXl-CVNAJst1Soq1clrzIt3UlVIv9t53qtNlI9vI45xoTsMp7Z5qfl-1iey3fl_FSKUJczdqO3jtaDHX2xeXFruw6cROHRntROfHfT0&amp;t=636656263693116770" type="text/javascript"></script>
<div>

<input type="hidden" name="__VIEWSTATEGENERATOR" id="__VIEWSTATEGENERATOR" value="DA3437FB">
<input type="hidden" name="__EVENTVALIDATION" id="__EVENTVALIDATION" value="/wEdAAiA9LzXZSLjxI7x+UirTXe0+7XkQu4d7nK4EBiRem09SRXLXkfLBDwIDDF/4bCuMfRZdDqbhO4rIylzXJimXCUfMmVHg289BUCGC5DYRgaC2q9eUVRudpA0pa9aTzxfUnw3d99vLaHpeECbB0w5GwZJWKCxaQ5SewBcaokODBorp1IkQ9C8HjBCQuVSUJoFsiyZv4cfZ4esfz1gfpGiZ02I">
</div>
  <script type="text/javascript">
//<![CDATA[
Sys.WebForms.PageRequestManager._initialize('ctl00$ScriptManager1', 'aspnetForm', [], [], [], 90, 'ctl00');
//]]>
</script>

  <div>
      <span id="dirtyForm" display="none"></span>
      <span id="ctl00_Label1"></span>

  </div>
  <div id="wrapper">
      <div id="header">
          <h1><a href="default.aspx">Arizona State Employee Charitable Campaign</a></h1>
          <img id="ctl00_imgHeader" src="assets/img/secc.png" alt="Arizona State Employee Charitable Campaign" style="border-width:0px;">
      </div>
      <div id="nav">

                  <ul>
                      <li>
                        <a id="ctl00_LoginView1_LoginStatus1" title="Logout" href="contributor/auth/dologout">LOGOUT</a>
                      </li>
                      <!--<li><a href="feedback.aspx" title="Feedback">FEEDBACK</a></li>//-->
                      <li><a href="Download/Instructions.pdf" title="Instructions" target="_blank">INSTRUCTIONS</a></li>
                      <li><a href="contributor/welcome" title="Home">HOME</a></li>
                  </ul>

      </div>
      <div id="container">

  <div id="container">
      <h2>
          Contributions for {{this.seccdonateService.contributor.firstName + ' ' + this.seccdonateService.contributor.lastName}}</h2>

              <br>
      <table cellpadding="3" cellspacing="0" border="0">
          <tbody>
        <!--
            <tr>
              <td align="right" style="width: 100px">
                  Residence Zip Code:
              </td>

              <td style="width: 96px" colspan="3">
                  <input name="zipCode" type="text" id="zipCode" maxlength="50" style="width: 172px;" [(ngModel)]="zipCode">
              </td>
          </tr>
        -->
        <tr>
            <td>
                <mat-form-field appearance="outline">
                    <mat-label>Residence Zip Code</mat-label>
                    <input matInput placeholder="Residence Zip Code" name="zipCode" [(ngModel)]="zipCode" (ngModelChange)="isContributorUpdated=false">
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td width="50%">
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Email" name="email" [(ngModel)]="email" (ngModelChange)="isContributorUpdated=false">
                </mat-form-field>&nbsp;&nbsp;
                <button mat-flat-button id="updateContributor" (click)="updateContributor()" color="primary" >Update</button>
                <span *ngIf="isContributorUpdated" ><font size="1" color="red" >&nbsp;&nbsp;&nbsp;&nbsp;updated</font></span>
            </td>
            <td width="50%">
                <!--Spin City ! -->
                <span *ngIf="isUpdating">
                    <mat-spinner [diameter]="45" class="mat-spinner-color"></mat-spinner>
                </span>
            </td>
         </tr>
         <tr>
          <td><span *ngIf="isEmailOrZipEmpty" ><font size="1" color="red" >Both email and zip required</font></span></td>
         </tr>


      </tbody>
    </table>
      <br>
      <div class="formcopy">
          <p>
              Please check the box if you are interested in being contacted to learn more about volunteer opportunities at your selected charity/charities.</p>
      </div>
      <br>


      <!-- CONTRIBUTIONS Table-->

      <table mat-table [dataSource] = "contributions"  cellpadding="3" cellspacing="3" border="0" width="80%">


        <!--  ST Code -->
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef> ST Code </th>
                <td mat-cell *matCellDef="let contribution"> {{contribution.code}} </td>
            </ng-container>


        <!--  Title -->
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Organization Name </th>
            <td mat-cell *matCellDef="let contribution"> {{contribution.nonProfitTitle}} </td>
        </ng-container>

        <!--  Amount -->
        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Annual (26 pay periods) Amount</th>
            <td mat-cell *matCellDef="let contribution"> {{contribution.amount * payFactor| currency}} </td>
        </ng-container>


        <!--  Volunteer --> <!--TODO set model correctly-->

        <ng-container matColumnDef="volunteer">
            <th mat-header-cell *matHeaderCellDef>Volunteer Info</th>
            <td mat-cell *matCellDef="let contribution">
                <input type="checkbox" mat-checkbox  (change)="updateVolunteerInfo(contribution.code, $event)">
            </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
      <!-- END Mat-Table-->


      <p style="margin-top: 25px;">
          <strong>TOTAL: {{ calcTotal() | currency }}</strong></p>
      <div align="center" style="margin-top: 10px;">
          <div id="options">
              <h4>
                  The information above accurately represents the organizations(s)<br>
                  and annual donation amount(s) I wish to contribute.</h4>
              <input value="Agree" name="info_accuracy_pledge" type="radio" id="info_accuracy_pledge_agree" value="agree"   [(ngModel)]="accuracyPledge">I
              Agree
              <input value="Disagree" name="info_accuracy_pledge" type="radio" id="info_accuracy_pledge_disagree" value="disagree" [(ngModel)]="accuracyPledge">I
              Disagree<br>
              <span *ngIf="isAccuracyPledgeReminder"><font size='1' color="red">You must agree to continue</font></span>
              <br>
              <div id="ctl00_mainContent_Message" class="reqd">
              </div>
              (You must Agree to Continue)
              </div>
      </div>
      <div>&nbsp;</div>
      <div>&nbsp;<span *ngIf="isInValid"><font size='1' color="red">Please update email and zip code before continuing</font></span></div>
      <p style="text-align: center">
          <button mat-raised-button (click)="editContributions()">Edit Contributions</button>
          &nbsp;&nbsp;&nbsp;
          <button mat-raised-button (click)="continue()" color="primary">Continue</button>
      </p>
  </div>

      </div>
      <div id="footer">
          <table width="100%">
              <tbody><tr>
                  <td width="47%"><a href="mailto:Linda.Stiles@azdoa.gov" id="ctl00_aDirector" target="_blank">Linda.Stiles@azdoa.gov</a>
                  </td>
                  <td>
                      <a href="default.aspx">SECC Home</a>
                  </td>
                  <td style="text-align: right; font-weight: bold;">
                      Version: 2.0.0
                  </td>
              </tr>
          </tbody></table>
      </div>
  </div>

</form>


<div class="ui-dialog ui-widget ui-widget-content ui-corner-all  ui-draggable ui-resizable" tabindex="-1" role="dialog" aria-labelledby="ui-dialog-title-ctl00_Timeout1" style="display: none; position: absolute; overflow: hidden; z-index: 1000; outline: 0px;"><div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix" unselectable="on"><span class="ui-dialog-title" id="ui-dialog-title-ctl00_Timeout1" unselectable="on">Session Expiring</span><a href="#" class="ui-dialog-titlebar-close ui-corner-all" role="button" unselectable="on"><span class="ui-icon ui-icon-closethick" unselectable="on">close</span></a></div><span id="ctl00_Timeout1" style="" class="ui-dialog-content ui-widget-content">
              <p>
                  <span class="ui-icon ui-icon-alert" style="float: left; margin: 1px 10px 20px 0;">
                  </span>Your session will expire in.
              </p>
              <span id="tscCounter"></span>&nbsp;seconds
              <p>
                  Click <b>OK</b> to continue your session.</p>
          </span><div class="ui-resizable-handle ui-resizable-n" unselectable="on"></div><div class="ui-resizable-handle ui-resizable-e" unselectable="on"></div><div class="ui-resizable-handle ui-resizable-s" unselectable="on"></div><div class="ui-resizable-handle ui-resizable-w" unselectable="on"></div><div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se ui-icon-grip-diagonal-se" unselectable="on" style="z-index: 1001;"></div><div class="ui-resizable-handle ui-resizable-sw" unselectable="on" style="z-index: 1002;"></div><div class="ui-resizable-handle ui-resizable-ne" unselectable="on" style="z-index: 1003;"></div><div class="ui-resizable-handle ui-resizable-nw" unselectable="on" style="z-index: 1004;"></div><div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix"><button type="button" class="ui-state-default ui-corner-all">Ok</button></div></div></body>
