import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from './../../../services/admin.service';
import { Subscription } from 'rxjs';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { Designation } from '../../../models/designation.model';
import { Contributionentry } from '../../../models/contributionentry.model';
import { SeccdonateService } from '../../../services/seccdonate.service';
import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';


@Component({
  selector: 'app-contributionentry',
  templateUrl: './contributionentry.component.html',
  styleUrls: ['./contributionentry.component.css']
})
export class ContributionentryComponent implements OnInit {


  payrollEnabledSubscription : Subscription = new Subscription();
  saveContributionsSubscription : Subscription = new Subscription();
  contributionEntry : Contributionentry = new Contributionentry();


  isPayrollEnabled : boolean;

  constructor(private adminSvc : AdminService,
              private seccdonateService : SeccdonateService,
              private router : Router,
              private sessiontimeoutService : SessiontimeoutService
            ) { }

  ngOnInit(): void {
    this.sessiontimeoutService.reset();
    this.contributionEntry.contributor = this.adminSvc.contributor;
    this.setPayrollEnabledAdmin(this.adminSvc.contributor.id);

  }


  saveContributions() {

      this.saveContributionsSubscription =

                    this.adminSvc.saveContributions(this.contributionEntry).subscribe(
                        (response : any) => {
                          console.log(response);
                          if (response.status == 'success') {
                             this.adminSvc.trxId = response.trxId;
                             this.adminSvc.contributionEntry = this.contributionEntry; // save for other pages
                             if(response.paymtType=='CA') {
                                window.location.href = response.redirectUrl;
                             }
                             if(response.paymtType=='PRD') {
                              this.seccdonateService.firstDeductionDate = response.firstDeductionDate;
                              this.router.navigate(['admin/receipt']);
                             }
                          }
                        },
                    err => {
                        console.log(err);
                      }
                    );

  }

  onDateInput(event: MatDatepickerInputEvent<Date>) {

   this.contributionEntry.pledgeDate = event.value?event.value : new Date();
  }

  addDesignationRow() {
    let desig : Designation = new Designation();
    desig.clickAndGive = '0'; // for contributions submitted by Admin
    this.contributionEntry.designations.push(desig);
  }


  private setPayrollEnabledAdmin(contribId : string) {

    //isPayrollEnabled() should not sent contrib Id from client.
    //TODO Leaving AS-IS but Server Side should be modified to read from session.
    this.payrollEnabledSubscription = this.adminSvc.isPayrollEnabledAdmin(contribId).subscribe(

    result => {
		  let payrollStatus = result as any;
        this.isPayrollEnabled = payrollStatus.enabled;
    } ,
        err => {
			console.log(err);
      }
    );
  }

  calculateTotal() {
    let total : Number = 0.00;
    for(let item of this.contributionEntry.designations) {
      total = +total + +item.amount; //strange syntax but it's how it is
    }
    this.contributionEntry.total = total.toString();
  }




  ngOnDestroy() {

    if(this.payrollEnabledSubscription != undefined) this.payrollEnabledSubscription.unsubscribe();
    if(this.saveContributionsSubscription != undefined) this.saveContributionsSubscription.unsubscribe();
  }

}
