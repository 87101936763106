<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  <title>
    SECC Online Donations
  </title>
</head>

<body>


  <form name="aspnetForm" method="post" action="./history.aspx" id="aspnetForm">

    <script
      src="/Donate/WebResource.axd?d=JbDTahrdrbkLlH8o_JdgA9ikMXEcF_EJc6Z3OJN6RSNN-yHnEbHAbSAKxANE1xGypJ82kpWTD6VsrrsyxV6Vf9C_FJOG4AJyiBbuX3BUJS01&amp;t=637814401746327080"
      type="text/javascript"></script>


    <script type="text/javascript">
      //<![CDATA[
      function CallServer(arg, context) { WebForm_DoCallback('ctl00$Timeout1', arg, ReceiveServerData, context, null, false); }//]]>
    </script>

    <script
      src="/Donate/ScriptResource.axd?d=dsj5ACFzcBEGRMZL6hrpaW7FP-xanaiRs0mQ6-JSUvXhX2gbyyaij3ZaTyoI5ZisZTuU8txuqE5eq-r5IkSotnHDh0WeqFM6XQlKO-qdy-bgqc8hFT0MHIc-Kj48AjuNYEGvXvPOVfnTOfkyFrzEWg2&amp;t=ffffffffcda39869"
      type="text/javascript"></script>
    <script type="text/javascript">
      //<![CDATA[
      if (typeof (Sys) === 'undefined') throw new Error('ASP.NET Ajax client-side framework failed to load.');
      //]]>
    </script>

    <script
      src="/Donate/ScriptResource.axd?d=jAL1FnEauOs4rW5Ys2pIBxXBQy2P0SkXnHvsHRj1hvOgIqtEIx9vDSrcAQYBN5cYs1YBsK3u60iB96dzyg_rDSXlFG56oI_imNFQR8AITrJSzeLMrEnt6X0_LKorse4HuCsn3BHqY1LJYDh9VOPOkQ2&amp;t=ffffffffcda39869"
      type="text/javascript"></script>
    <script
      src="/Donate/WebResource.axd?d=r7RIgbZ8ZkVe0Zi-2-FAckt9JkvXl-CVNAJst1Soq1clrzIt3UlVIv9t53qtNlI9vI45xoTsMp7Z5qfl-1iey3fl_FSKUJczdqO3jtaDHX2xeXFruw6cROHRntROfHfT0&amp;t=636656263693116770"
      type="text/javascript"></script>
    <div>

      <input type="hidden" name="__VIEWSTATEGENERATOR" id="__VIEWSTATEGENERATOR" value="07C9BEDB">
      <input type="hidden" name="__EVENTVALIDATION" id="__EVENTVALIDATION"
        value="/wEdAAJHWx4U7wCB04Funq6an/ab+7XkQu4d7nK4EBiRem09STOhqHVLBBjfr1R2R+LS2Up+cDG3HxGwM9njqm3lRFAk">
    </div>
    <script type="text/javascript">
      //<![CDATA[
      Sys.WebForms.PageRequestManager._initialize('ctl00$ScriptManager1', 'aspnetForm', [], [], [], 90, 'ctl00');
      //]]>
    </script>

    <div>
      <span id="dirtyForm" display="none"></span>
      <span id="ctl00_Label1"></span>

    </div>
    <div id="wrapper">
      <div id="header">
        <h1><a href="default.aspx">Arizona State Employee Charitable Campaign</a></h1>
        <img id="ctl00_imgHeader" src="assets/img/secc.png" alt="Arizona State Employee Charitable Campaign"
          style="border-width:0px;">
      </div>
      <div id="nav">

        <ul>
          <li>
            <a id="ctl00_LoginView1_LoginStatus1" title="Logout" href="contributor/auth/dologout">LOGOUT</a>
          </li>
          <!--<li><a href="feedback.aspx" title="Feedback">FEEDBACK</a></li>//-->
          <li><a href="assets/doc/Instructions.pdf" title="Instructions" target="_blank">INSTRUCTIONS</a></li>
          <li><a href="contributor/welcome" title="Home">HOME</a></li>
        </ul>

      </div>
      <div id="container">

        <div style="padding-right: 40px;">
          <h2>
            Hello <span style="text-transform: capitalize;">
              <span id="ctl00_mainContent_lblName">{{this.seccdonateService.userFullName}}</span></span>
          </h2>

          <p style="padding-top: 20px;">
            We have donations from you for the following years. If you feel that this information
            is in error please contact the SECC at <a href="mailto:secc@azdoa.gov">secc@azdoa.gov</a>.
          </p>

          <div *ngIf="isShowLoading">
            <font color="red">Loading. Please wait... </font>
          </div>

          <div id="spinarea-secc"></div>
          <div style="padding-top: 20px;">
            <table width="100%" cellpadding="10" cellspacing="0" border="0">

              <tbody>

                <tr *ngFor="let item of designationTrxList">
                  <td style="width: 50%; text-align: right;">
                    <span id="participantYear">{{item.designations[0].participantYear}}</span>
                  </td>
                  <td style="width: 50%; text-align: left;">
                    <a href="javascript:void(0)" (click)="showReceipt(item.id,
                                                                      item.designations[0].participantYear,
                                                                      item.paymentType
                                                                      )">View</a>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>

        </div>
        <div *ngIf="isReadyToDonateVisible()" id="ctl00_mainContent_pnlStart" style="float: right; padding-top: 20px; padding-right: 40px;">
          <h2>
            I am ready to
            <a id="ctl00_mainContent_hlStart" href="contributor/designations">donate</a>
          </h2>
        </div>

      </div>
      <div id="footer">
        <table width="100%">
          <tbody>
            <tr>
              <td width="47%"><a href="mailto:Linda.Stiles@azdoa.gov" id="ctl00_aDirector"
                  target="_blank">Linda.Stiles@azdoa.gov</a>
              </td>
              <td>
                <a href="contributor/welcome">SECC Home</a>
              </td>
              <td style="text-align: right; font-weight: bold;">
                Version: 2.0.0
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>


  <div class="ui-dialog ui-widget ui-widget-content ui-corner-all  ui-draggable ui-resizable" tabindex="-1"
    role="dialog" aria-labelledby="ui-dialog-title-ctl00_Timeout1"
    style="display: none; position: absolute; overflow: hidden; z-index: 1000; outline: 0px;">
    <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix" unselectable="on"><span
        class="ui-dialog-title" id="ui-dialog-title-ctl00_Timeout1" unselectable="on">Session Expiring</span><a href="#"
        class="ui-dialog-titlebar-close ui-corner-all" role="button" unselectable="on"><span
          class="ui-icon ui-icon-closethick" unselectable="on">close</span></a></div><span id="ctl00_Timeout1" style=""
      class="ui-dialog-content ui-widget-content">
      <p>
        <span class="ui-icon ui-icon-alert" style="float: left; margin: 1px 10px 20px 0;">
        </span>Your session will expire in.
      </p>
      <span id="tscCounter"></span>&nbsp;seconds
      <p>
        Click <b>OK</b> to continue your session.</p>
    </span>
    <div class="ui-resizable-handle ui-resizable-n" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-e" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-s" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-w" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se ui-icon-grip-diagonal-se"
      unselectable="on" style="z-index: 1001;"></div>
    <div class="ui-resizable-handle ui-resizable-sw" unselectable="on" style="z-index: 1002;"></div>
    <div class="ui-resizable-handle ui-resizable-ne" unselectable="on" style="z-index: 1003;"></div>
    <div class="ui-resizable-handle ui-resizable-nw" unselectable="on" style="z-index: 1004;"></div>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix"><button type="button"
        class="ui-state-default ui-corner-all">Ok</button></div>
  </div>
</body>
