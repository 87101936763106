<html xmlns="http://www.w3.org/1999/xhtml">

<head id="ctl00_Head1">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <title>SECC Online Donations</title>
</head>

<body>
  <form name="aspnetForm" method="post" id="aspnetForm">
    <div></div>

    <div>
      <span id="dirtyForm" display="none"></span>
      <span id="ctl00_Label1"></span>
      <span id="ctl00_Timeout1" title="Session Expiring" style="display: none">
        <p>
          <span class="ui-icon ui-icon-alert" style="float: left; margin: 1px 10px 20px 0">
          </span>Your session will expire in.
        </p>
        <span id="tscCounter"></span>&nbsp;seconds
        <p>Click <b>OK</b> to continue your session.</p>
      </span>
    </div>
    <div id="wrapper">
      <div id="header">
        <img id="ctl00_imgHeader" src="assets/img/header.jpg" alt="SECC Header and Logo"
          style="height: 190px; width: 890px; border-width: 0px" />
      </div>

      <div id="nav">

        <ul>
          <li>
            <a id="ctl00_LoginView1_LoginStatus1" title="Logout" href="admin/auth/dologout">LOGOUT</a>
          </li>

          <li><a id="ctl00_LoginView1_hlHome" title="Home" href="admin/home">HOME</a></li>
        </ul>

      </div>

      <div>

        <table width="100%" border="1">
          <tbody>
            <tr style="background-color:Aqua">
              <td>
                <div id="ctl00_LoginView2_UpdatePanel1">

                  <div style="width: 100%; height: 28px">
                    <div id="ctl00_LoginView2_timer" style="border-style:Solid;">


                      <div style="width: 100%; height: 100%; vertical-align: middle; text-align: center;">
                        <span id="ctl00_LoginView2_currentTime" style="font-size: large; font-weight: bold; ">
                          &nbsp;&nbsp;&nbsp;&nbsp;Payroll Deduction: {{bannerSvc.donationSummaryList[0].prd | currency}}
                          &nbsp;&nbsp;&nbsp;&nbsp;One Time
                          Payments/Special Events: {{bannerSvc.donationSummaryList[0].cc | currency}}</span>
                      </div>


                    </div>
                  </div>

                </div>


              </td>
            </tr>
          </tbody>
        </table>

      </div>

      <div id="container">
        <div id="homewrap">

          <div>
            <font size='5'><b>Donations by Agency</b></font>
          </div>
          <p></p>
           <div>
            <span>Export to </span>
            <span class="exportLink"><a (click)="generatePdf()"><b>PDF</b></a></span>
            <span>&nbsp;&nbsp;&nbsp;</span>
            <span class="exportLink"><a (click)="generateExcel()"><b>Excel</b></a></span>

          </div>
          <p>&nbsp;</p>


          <p-paginator [first]="0" [rows]="3" [totalRecords]="donations.length" (onPageChange)="onPageChange($event)">
            <ng-template pTemplate="paginatorprevious">
              <p-button type="button" icon="pi pi-angle-left" label="Previous"></p-button>
            </ng-template>
            <ng-template pTemplate="paginatornext">
              <p-button type="button" icon="pi pi-angle-right" label="Next"></p-button>
            </ng-template>

          </p-paginator>

          <div *ngFor="let tables of currentPageData">


            <p-card header="">
              <p-table [value]="tables" styleClass="p-datatable-gridlines">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="header">Agency Code</th>
                    <th class="header">Payment Type</th>
                    <th class="header">Donation Amount</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-table>
                  <tr>
                    <td class="data">{{ table.agencyCode }}</td>
                    <td class="data"> {{ table.paymentType }}</td>
                    <td class="data">{{ table.amount | currency}}</td>
                  </tr>
                </ng-template>
              </p-table>

              <div id="tableTotal">
                <table style="table-layout: fixed;width: 100%;">
                  <tr class="total">
                    <td></td>
                    <td style="text-align: right"><b>TOTAL :</b></td>
                    <td style="text-align: center;background-color:rgb(211, 225, 243)">
                      <font size='4'><b>{{calcTotal(tables) | currency}}</b></font>
                    </td>
                  </tr>
                </table>
              </div>
            </p-card>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
          </div>
        </div>
      </div>

      <div id="footer">
        <ul>
          <li><b>Version: 2.0.0 </b>:: <a href="/">SECC Home</a></li>
        </ul>
      </div>
    </div>
  </form>

  <span id="okta-plugin-message-channel-available" style="display: none"></span>
</body>

</html>
