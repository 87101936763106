export class SearchParams {

  //NonProfit Search 

  //From form
    textSearch : string;
    serviceSearch : string;
    stCodeSearch : string;
    locationSearch : string;
    locationSelectedCounty : string;  // if search by county

    //hidden
    //participantYear : string;

    constructor() {

      this.textSearch = null;
      this.serviceSearch = null;
      this.stCodeSearch = null;
      this.locationSearch = null;
      this.locationSelectedCounty = null;
      //this.participantYear = null;

    }



}
