<html xmlns="http://www.w3.org/1999/xhtml">

<head id="ctl00_Head1">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <title>SECC Online Donations</title>
</head>

<body>
  <form name="aspnetForm" method="post" id="aspnetForm">
    <div></div>

    <div>
      <span id="dirtyForm" display="none"></span>
      <span id="ctl00_Label1"></span>
      <span id="ctl00_Timeout1" title="Session Expiring" style="display: none">
        <p>
          <span class="ui-icon ui-icon-alert" style="float: left; margin: 1px 10px 20px 0">
          </span>Your session will expire in.
        </p>
        <span id="tscCounter"></span>&nbsp;seconds
        <p>Click <b>OK</b> to continue your session.</p>
      </span>
    </div>
    <div id="wrapper">
      <div id="header">
        <img id="ctl00_imgHeader" src="assets/img/header.jpg" alt="SECC Header and Logo"
          style="height: 190px; width: 890px; border-width: 0px" />
      </div>

      <div id="nav">

        <ul>
          <li>
            <a id="ctl00_LoginView1_LoginStatus1" title="Logout" href="admin/auth/dologout">LOGOUT</a>
          </li>

          <li><a id="ctl00_LoginView1_hlHome" title="Home" href="admin/home">HOME</a></li>
        </ul>

      </div>

      <div>

        <table width="100%" border="1">
          <tbody>
            <tr style="background-color:Aqua">
              <td>
                <div id="ctl00_LoginView2_UpdatePanel1">

                  <div style="width: 100%; height: 28px">
                    <div id="ctl00_LoginView2_timer" style="border-style:Solid;">


                      <div style="width: 100%; height: 100%; vertical-align: middle; text-align: center;">
                        <span id="ctl00_LoginView2_currentTime" style="font-size: large; font-weight: bold; ">
                          &nbsp;&nbsp;&nbsp;&nbsp;Payroll Deduction: {{bannerSvc.donationSummaryList[0].prd | currency}}
                          &nbsp;&nbsp;&nbsp;&nbsp;One Time
                          Payments/Special Events: {{bannerSvc.donationSummaryList[0].cc | currency}}</span>
                      </div>


                    </div>
                  </div>

                </div>


              </td>
            </tr>
          </tbody>
        </table>

      </div>

      <div id="container">

        <div id="ctl00_mainContent_UpdatePanel1">

          <h2>
            <span id="ctl00_mainContent_lblHeader">Special Event Update</span>
          </h2>
          <table cellpadding="2" width="100%">
            <tbody>
              <tr>
                <td>
                  Agency:
                </td>
                <td>
                  <select name="ctl00$mainContent$ddlAgency" id="ctl00_mainContent_ddlAgency"
                    [(ngModel)]="adminSvc.selectedSpecialEvent.specialEvent.agencyCode">
                    <option selected="selected" value="">Select Agency</option>
                    <option *ngFor="let item of agencies" [value]="item.hrisagencyCode">{{ item.hrisagencyTitle }}
                    </option>
                  </select>

                  <span id="ctl00_mainContent_rfvAgency" style="color:Red;visibility:hidden;"><img
                      src="assets/img/alert.png" alt="Alert"></span>
                </td>
                <td>
                  Event #:
                </td>
                <td>
                  <input name="ctl00$mainContent$txtEventNo" type="text" maxlength="50"
                    id="ctl00_mainContent_txtEventNo"
                    [(ngModel)]="adminSvc.selectedSpecialEvent.specialEvent.specialEventNo">
                  <span id="ctl00_mainContent_rfvEventNo" style="color:Red;visibility:hidden;"><img
                      src="assets/img/alert.png" alt="Alert"></span>
                </td>
              </tr>
              <tr>
                <td>
                  Amount $:
                </td>
                <td>
                  <input name="ctl00$mainContent$txtAmount" type="text" value="0" maxlength="10"
                    id="ctl00_mainContent_txtAmount" [(ngModel)]="adminSvc.selectedSpecialEvent.specialEvent.amount">
                </td>
                <td>
                  Event Date:
                </td>
                <td>
                  <mat-form-field>
                    <input
                      matInput
                      [value]="adminSvc.selectedSpecialEvent.specialEvent.eventDate"
                      [matDatepicker]="eventDatepicker"
                      (dateInput)="onDateInput($event)"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="eventDatepicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #eventDatepicker></mat-datepicker>
                  </mat-form-field>

                </td>
              </tr>
              <tr>
                <td>
                  Contact Name:
                </td>
                <td>
                  <input name="ctl00$mainContent$txtContactName" type="text" maxlength="50"
                    id="ctl00_mainContent_txtContactName"
                    [(ngModel)]="adminSvc.selectedSpecialEvent.specialEvent.contactName">
                </td>
                <td>
                  Contact Phone:
                </td>
                <td>
                  <input name="ctl00$mainContent$txtContactPhone" type="text" maxlength="15"
                    id="ctl00_mainContent_txtContactPhone"
                    [(ngModel)]="adminSvc.selectedSpecialEvent.specialEvent.contactPhone">
                </td>
              </tr>
            </tbody>
          </table>
          <br>
          <br>
          <input type="hidden" name="ctl00$mainContent$hdnSpecialEventID" id="ctl00_mainContent_hdnSpecialEventID"
            value="0" [(ngModel)]="adminSvc.selectedSpecialEvent.specialEvent.id">
          <input type="hidden" name="ctl00$mainContent$hdnParticipantYear" id="ctl00_mainContent_hdnParticipantYear"
            value="0">
          <strong>NonProfit Distribution:</strong>
          <div>
            <table cellspacing="0" rules="all" border="1" id="ctl00_mainContent_gvSpecialEventDetail"
              style="width:97%;border-collapse:collapse;">
              <tbody>
                <tr>
                  <th scope="col" style="width:55px;">Action</th>
                  <th scope="col">NonProfit</th>
                  <th scope="col">Amount</th>
                </tr>
                <tr>
                  <td colspan="3" style="width:100%;"></td>
                </tr>

                <tr *ngFor="let item of adminSvc.selectedSpecialEvent.specialEventDetailList; let i = index ">


                <!-- EDIT BUTTON PANEL -->
                  <td [id]="'seDetail_editbtn_' + i" >
                    <input type="image" name="ctl00$mainContent$gvSpecialEventDetail$ctl02$Edit"
                      (click)="switchToEditMode(item, i)" [name]="'seDetail_edit_' + i"
                       title="Edit Record." text="Edit"
                      src="assets/img/edit.gif" alt="Edit"
                      style="height:15px;width:15px;border-width:0px;">
                      &nbsp; &nbsp;
                      <input type="image" name="ctl00$mainContent$gvSpecialEventDetail$ctl02$Delete" [name]="'seDetail_delete_' + i"
                        (click)="delete(item,i)"
                        title="Delete Record." text="Delete"
                      src="assets/img/delete.png" alt="Delete"
                      style="height:15px;width:15px;border-width:0px;">
                  </td>

                <!--CANCEL BUTTON PANEL-->
                  <td [id]="'seDetail_cancelbtn_' + i" style="width:55px;">
                    <input type="image" name="ctl00$mainContent$gvSpecialEventDetail$ctl03$Insert" [name]="'seDetail_save_' + i"
                      (click)="saveSEDetail(item,i)"
                      title="Save new record." text="Insert"
                      src="assets/img/save.png" alt="Save"
                      style="height:15px;width:15px;border-width:0px;">
                      &nbsp; &nbsp;

                      <input type="image" name="ctl00$mainContent$gvSpecialEventDetail$ctl03$Cancel" [name]="'seDetail_cancel_' + i"
                      (click)="cancelEdit(item, i)"
                      title="Cancel the addition of the new Record." text="Cancel" src="assets/img/cancel.png"
                      alt="Cancel" style="height:15px;width:15px;border-width:0px;">
                  </td>

                <!--NON PROFIT EDIT-->
                  <td [id]="'seDetail_currNonProfit_' + i">
                   {{item.npTitle}}
                  </td>
                  <td [id]="'seDetail_selectedNonProfit_' + i">
                    <select name="ctl00$mainContent$gvSpecialEventDetail$ctl03$ddlNPTitleInsert"
                      [id]="'np_dropDown_' + i">
                      <option *ngFor="let item of nonProfits"   [value]="item.nonProfitId">{{item.nonProfitTitle}}
                      </option>
                    </select>
                  </td>

                <!-- AMOUNT EDIT -->
                  <td align="right">
                   $<input type ="text" [name]="'seDetail_amount_' + i" [id]="'seDetail_amount_' + i"  value={{item.amount}}
                                                        [disabled]="true">
                  </td>
                </tr>

              </tbody>
            </table>
          </div>


          <table width="94%">
            <tbody>
              <tr>
                <td>
                  <input type="button" (click)="addNew()" name="ctl00$mainContent$btnAdd" value="Add" id="ctl00_mainContent_btnAdd">
                </td>
                <td>&nbsp;</td>
                <td align="right">
                  Total:
                  <span id="ctl00_mainContent_lblTotal" style="color:Gray;font-weight:bold;">{{calcTotal() | currency}}</span>
                </td>
              </tr>
              <tr align="center">
                <td colspan="2">
                  <input type="submit" name="ctl00$mainContent$btnBack" value="<< Back" id="ctl00_mainContent_btnBack"
                  (click)="goBack()"
                    style="width:100px;">
                  <input type="submit" name="ctl00$mainContent$BtnSave" value="Save" id="ctl00_mainContent_BtnSave"
                  (click)="saveEdit()"
                    style="width:100px;">
                </td>
              </tr>
              <tr>
                <td>
                  <span id="ctl00_mainContent_lblMessage" style="color:Green;font-weight:bold;"></span>
                </td>
              </tr>
              <tr>
                <td>
                  <span *ngIf="isAmountMismatch()" id="ctl00_mainContent_lblErrorMessage" style="color:Red;font-weight:bold;">
                    Error: Distribution Total does not match the Amount.
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

        </div>

      </div>

      <div id="footer">
        <ul>
          <li><b>Version: 2.0.0 </b>:: <a href="/">SECC Home</a></li>
        </ul>
      </div>
    </div>
  </form>

  <span id="okta-plugin-message-channel-available" style="display: none"></span>
</body>

</html>
