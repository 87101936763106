import { Designation } from './../../../models/designation.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployeeidlookupComponent } from '../employeeidlookup/employeeidlookup.component';

import { BannerService } from 'src/app/services/banner.service';
import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-deletedonation',
  templateUrl: './deletedonation.component.html',
  styleUrls: ['./deletedonation.component.css']
})
export class DeletedonationComponent implements OnInit {

  donationsForDeleteSubscription: Subscription = new Subscription();
  deleteDonationSubscription: Subscription = new Subscription();

  isShowMessage: boolean = false;
  message: string = '';

  donationsForDelete: any = {}; // main object containing DesignationTransaction with embedded Contributor object
  isShowDonations: boolean = false;

  donationsTotal: Number = 0;

  constructor(public bannerSvc: BannerService,
    public adminSvc: AdminService,
    private router: Router,
    private dialog: MatDialog,
    private sessiontimeoutService: SessiontimeoutService


  ) { }

  ngOnInit(): void {

    this.sessiontimeoutService.reset();
    this.isShowMessage = false;
    this.isShowDonations = false;
    this.bannerSvc.getDonationSummaryAll();
  }


  getDonationsForDelete() {

    this.donationsForDeleteSubscription = this.adminSvc.getDonationsForDelete(this.adminSvc.employeeEin).subscribe(

      result => {
        this.donationsForDelete = result as any;

        if(this.donationsForDelete.id == null) {  //No donations found
          this.isShowDonations = false; // Already false..but what the heck.
          this.isShowMessage = true;
          this.message = 'No transaction found';
          return;
        } else {
          this.isShowMessage = false;
          this.message = '';
        }

        console.log(this.donationsForDelete);
        this.calcDonatationTotal();
        this.isShowDonations = true;
      },
      err => {
        console.log(err);
      }
    )
  }

  calcDonatationTotal() {

    var total: Number = 0;
    if (this.donationsForDelete.designations != undefined) {
      this.donationsForDelete.designations.forEach(item => {
        total = total + item.amount;
      })
    }
    this.donationsTotal = total;
  }


  showEmpIdLookupModal() {

    const dialogRef = this.dialog.open(EmployeeidlookupComponent, {
      width: 'auto',
      height: 'auto'

    });

    dialogRef.afterClosed().subscribe(result => {


    });
  }

  private deleteDonation() {

    var trxId = this.donationsForDelete.id;

    this.deleteDonationSubscription = this.adminSvc.deleteDonation(trxId).subscribe(res => {
      const response = res as any;
      if (response.status == 'success') {
        this.isShowDonations = false;
        this.donationsForDelete = {}; // clear the
        ///
        this.message = 'Donation has been deleted';
        this.isShowMessage = true;
      }
    },
      err => {
        console.log(err);
      }
    )


  }

  askDeleteConfirm() {

    var confirmMesg = '';
    if (this.donationsForDelete.paymentType == 'CC') {
      confirmMesg = 'The donation was paid using a credit card and will need to be refunded. Are you sure you want to delete this donation?';
    } else {
      confirmMesg = 'Are you sure you want to delete this donation ?';
    }

    if (window.confirm(confirmMesg)) {
      console.log('Confirmed');
      this.deleteDonation();
    } else {
      console.log('Cancelled');
    }
  }




  ngOnDestroy() {

    if (this.donationsForDeleteSubscription != undefined) this.donationsForDeleteSubscription.unsubscribe();
    if (this.deleteDonationSubscription != undefined) this.deleteDonationSubscription.unsubscribe();
  }

}
