<html xmlns="http://www.w3.org/1999/xhtml">

<head id="ctl00_Head1">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  <title>
    Receipt
  </title>
</head>

<body>



  <form name="aspnetForm" method="post" action="" id="aspnetForm">
    <div>
    </div>

    <div>

    </div>

    <div>
      <span id="dirtyForm" display="none"></span>
      <span id="ctl00_Label1"></span>
      <span id="ctl00_Timeout1" title="Session Expiring" style="display:none;">
        <p>
          <span class="ui-icon ui-icon-alert" style="float: left; margin: 1px 10px 20px 0;">
          </span>Your session will expire in.
        </p>
        <span id="tscCounter"></span>&nbsp;seconds
        <p>
          Click <b>OK</b> to continue your session.</p>
      </span>
    </div>
    <div id="wrapper">
      <div id="header">

        <img id="ctl00_imgHeader" src="assets/img/header.jpg" alt="SECC Header and Logo"
          style="height:190px;width:890px;border-width:0px;">

      </div>
      <div id="nav">

        <ul>
          <li>
            <a id="ctl00_LoginView1_LoginStatus1" title="Logout"
            href="admin/auth/dologout">LOGOUT</a>
          </li>

          <li><a id="ctl00_LoginView1_hlHome" title="Home"  href="admin/home">HOME</a></li>
        </ul>

      </div>
      <div>

        <table width="100%" border="1">
          <tbody>
            <tr style="background-color:Aqua">
              <td>
                <div id="ctl00_LoginView2_UpdatePanel1">

                  <div style="width: 100%; height: 28px">
                    <div id="ctl00_LoginView2_timer" style="border-style:Solid;">

                      <div style="width: 100%; height: 100%; vertical-align: middle; text-align: center;">
                        <span id="ctl00_LoginView2_currentTime" style="font-size: large; font-weight: bold; ">
                          &nbsp;&nbsp;&nbsp;&nbsp;Payroll Deduction: {{adminSvc.donationSummaryList[0].prd | currency}} &nbsp;&nbsp;&nbsp;&nbsp;One Time
                          Payments/Special Events: {{adminSvc.donationSummaryList[0].cc | currency}}</span>
                      </div>

                    </div>
                  </div>

                </div>


              </td>
            </tr>
          </tbody>
        </table>

      </div>
      <div id="container">

        <h2>Contribution Confirmation</h2>
        <h3>{{adminSvc.contributor.firstName}} {{adminSvc.contributor.lastName}}</h3>
        <h3>Contribution Summary</h3>
        <p><strong>Date: </strong>{{today | date : "shortDate"}}</p>

        <p><strong>Campaign Week: </strong>{{adminSvc.contributionEntry.campaignWeek}}</p>

        <table cellpadding="3" cellspacing="3" border="0" width="80%">
          <tbody>
            <tr style="background-color:#fff;">
              <td><strong>Nonprofit<br>Code</strong></td>
              <td><strong>Organization<br>Name</strong></td>
              <td><strong>Annual<br>Amount</strong></td>

              <td><strong>Notify org<br>of my gift</strong></td>
            </tr>
            <tr  *ngFor="let contribution of contributions">
              <td>{{contribution.code}}</td>
              <td>{{contribution.nonProfitTitle}}</td>
              <td>{{contribution.amount | currency}}</td>
              <td>{{contribution.notifyRecipient === 'true' ? 'Yes' : 'No'}}</td>
            </tr>

            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>Total: </td>
              <td>{{total | currency}}</td>
            </tr>

          </tbody>
        </table>
        <p>Method of payment is <strong>{{methodOfPayment}}</strong></p>

        <div id="receiptbuttons">
          <input type="button" onclick="window.print();" value="Print">
          <input type="button" name="edit" value="SECC Home" >
        </div>

      </div>
      <div id="footer">
        <ul>
          <li><b>Version: 2.0.0 </b>:: <a href="/">SECC Home</a></li>
        </ul>
      </div>
    </div>

  </form>


  <span id="okta-plugin-message-channel-available" style="display: none;"></span>
</body>

</html>
