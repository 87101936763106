import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SeccdonateService } from '../../services/seccdonate.service';
import { ContributionOrder } from '../../models/contributionorder.model';
import { Subscription } from 'rxjs';
import { Designation } from '../../models/designation.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaypalmesgmodalComponent } from './../paypalmesgmodal/paypalmesgmodal.component';




@Component({
  selector: 'app-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.css']
})
export class SubmissionComponent implements OnInit {

  constructor(public seccdonateService : SeccdonateService,
              private router : Router,
              public dialog: MatDialog,
              private sessiontimeoutService : SessiontimeoutService
              ) { }
  submissionSubscription : Subscription = new Subscription();

  volunteerHours : number ;

  createContributionPledge : string;  //'agree' or 'disagree'
  isCreateContributionPledgeReminder : boolean; //show 'agree to continue' reminder message

  ngOnInit() {

    this.sessiontimeoutService.reset();
    this.volunteerHours = 0;

  }

  submit() {

    //Check Create Contribution pledge (agree/disagree);
    if(this.createContributionPledge != 'agree') {
      this.isCreateContributionPledgeReminder = true;
      return ;
    }

    //Show 'return to SECC' message (reminder)
    if(this.seccdonateService.paymentType=='CC') {
      this.showPaypalMessageModal();
      return;
    }

    this.postForm();
  }



  postForm() {


    this.isCreateContributionPledgeReminder = false;
    this.seccdonateService.volunteerHours = this.volunteerHours;

    //Do stuff to continue
    //console.log(this.seccdonateService);
    let  payload = this.buildOrderReq();

    var spinner = this.seccdonateService.startSpinner('spinarea-secc');
    this.submissionSubscription = this.seccdonateService.submitOrder(payload).subscribe(
      (response) => {
        console.log(response);
        this.seccdonateService.stopSpinner(spinner);
        if (response.status == 'success') {
           if(response.paymtType=='CC') {
              window.location.href = response.redirectUrl;
           }
           if(response.paymtType=='PRD') {
            this.seccdonateService.firstDeductionDate = response.firstDeductionDate;
            this.router.navigate(['contributor/receipt']);
           }
        }
        // other checks

      },
      err => {
        console.log(err);
        this.seccdonateService.stopSpinner(spinner);
      }
    );

    //this.router.navigate(['contributor/receipt']);
  }

  //Show 'Return from Paypal' message
showPaypalMessageModal() {

  const dialogRef = this.dialog.open(PaypalmesgmodalComponent, {
    width: '500px',
    height: 'auto',
    backdropClass: 'backdropBackground'
  });


  dialogRef.afterClosed().subscribe(result => {
    if (result == true) {  //From mat-dialog-result : true = continue; false = cancel
      this.postForm();
    }
  });
}





  editContributions() {

    this.router.navigate(['contributor/designations']);
  }

  back() {

    this.router.navigate(['contributor/paymentmethod']);
  }

/*
   npDonations : Array<NonprofitcontributeComponent>;  //donations saved here on 'continue'
    annualAmount : number;
    paymentType : string;
    volunteerHours : number;

    contribId : string = '30217'; //Hardcoded for now. TODO change after Login integration
    employeeId : string;
    ======================


*/
  buildOrderReq() : ContributionOrder {

    var order : ContributionOrder = new ContributionOrder();

    //Build Designations array
    this.seccdonateService.npDonations.forEach(donation => {

      var designation : Designation = new Designation();
      designation.amount = donation.amount.toString();
      designation.batchNumber = donation.batchNumber;
      designation.clickAndGive = donation.clickAndGive!=null? donation.clickAndGive.toString():"" ;
      designation.contributorID = donation.contributorId ; //Populated server side. Not read from client.
      designation.envelopeNumber = donation.envelopeNumber ;
      designation.exception = donation.exception!=null?donation.exception.toString():"" ;
      designation.instructions = donation.instructions ;
      designation.nonProfitID = donation.nonProfitId ;
      designation.nonProfitTitle = donation.nonProfitTitle;
      designation.notifyRecipient = donation.notifyRecipient!=null?donation.notifyRecipient.toString():"";
      designation.participantYear = donation.participantYear ;
      designation.transactionID = donation.transactionId ;
      designation.week = donation.week ;
      order.designations.push(designation);
    })

    //Build DesignationsTransaction object (order level info)
    order.isComplete = false;
    order.payFactor = this.seccdonateService.payFactor.toString();
    order.total = this.seccdonateService.annualAmount;
   // order.contributorID = this.seccdonateService.contribId.toString(); //Not needed. Populated server side from session
    order.paymentType = this.seccdonateService.paymentType;
    order.volunteerHours = this.seccdonateService.volunteerHours.toString();
    //To be set on server side :
    //1. Id (uuid)
    //2. CreateDate
    //3. SubmitDate

    return order;
  }

  ngOnDestroy(){

    if(this.submissionSubscription != undefined) this.submissionSubscription.unsubscribe();
  }

}

