<html xmlns="http://www.w3.org/1999/xhtml">

<head id="ctl00_Head1">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <title>SECC Online Donations</title>
</head>

<body>
  <form name="aspnetForm" method="post" id="aspnetForm">
    <div></div>

    <div>
      <span id="dirtyForm" display="none"></span>
      <span id="ctl00_Label1"></span>
      <span id="ctl00_Timeout1" title="Session Expiring" style="display: none">
        <p>
          <span class="ui-icon ui-icon-alert" style="float: left; margin: 1px 10px 20px 0">
          </span>Your session will expire in.
        </p>
        <span id="tscCounter"></span>&nbsp;seconds
        <p>Click <b>OK</b> to continue your session.</p>
      </span>
    </div>
    <div id="wrapper">
      <div id="header">
        <img id="ctl00_imgHeader" src="assets/img/header.jpg" alt="SECC Header and Logo"
          style="height: 190px; width: 890px; border-width: 0px" />
      </div>

      <div id="nav">

        <ul>
          <li>
            <a id="ctl00_LoginView1_LoginStatus1" title="Logout" href="admin/auth/dologout">LOGOUT</a>
          </li>

          <li><a id="ctl00_LoginView1_hlHome" title="Home" href="admin/home">HOME</a></li>
        </ul>

      </div>

      <div>

        <table width="100%" border="1">
          <tbody>
            <tr style="background-color:Aqua">
              <td>
                <div id="ctl00_LoginView2_UpdatePanel1">

                  <div style="width: 100%; height: 28px">
                    <div id="ctl00_LoginView2_timer" style="border-style:Solid;">


                      <div style="width: 100%; height: 100%; vertical-align: middle; text-align: center;">
                        <span id="ctl00_LoginView2_currentTime" style="font-size: large; font-weight: bold; ">
                          &nbsp;&nbsp;&nbsp;&nbsp;Payroll Deduction: {{bannerSvc.donationSummaryList[0].prd | currency}}
                          &nbsp;&nbsp;&nbsp;&nbsp;One Time
                          Payments/Special Events: {{bannerSvc.donationSummaryList[0].cc | currency}}</span>
                      </div>


                    </div>
                  </div>

                </div>


              </td>
            </tr>
          </tbody>
        </table>

      </div>

      <div id="container">

        <h2>Special Events</h2>
        <a  href="admin/specialevents/report"  id="ctl00_mainContent_lkbViewReport"
         >View
          Special Events Report</a>
        <br><br>
        <div>
          <table cellspacing="0" cellpadding="5" rules="all" border="1" id="ctl00_mainContent_gvSpecialEvent"
            style="border-collapse:collapse;">
            <tbody>
              <tr>
                <th scope="col">Action</th>
                <th scope="col">Event #</th>
                <th scope="col">Agency Code</th>
                <th scope="col">Contact Name</th>
                <th scope="col">Contact Phone</th>
                <th scope="col">Amount</th>
                <th scope="col">Event Date</th>
                <th scope="col">CreateDate</th>
                <th scope="col">CreatedBy EIN</th>
              </tr>



              <tr *ngFor="let item of specialEvents">
                <td align="center">
                  <input type="image" name="ctl00$mainContent$gvSpecialEvent$ctl02$Delete"
                    id="ctl00_mainContent_gvSpecialEvent_ctl02_Delete" title="Delete Contact." text="Delete"
                    src="assets/img/delete.png" alt="Delete" (click)="deleteEvent(item.id)"
                    style="height:15px;width:15px;border-width:0px;">
                </td>
                <td>
                  <a id="ctl00_mainContent_gvSpecialEvent_ctl02_imbSpecialEventNo" href="javascript:void(0);" (click)="getSpecialEventWithDetail(item.id)">{{item.specialEventNo}}</a>
                </td>
                <td>
                  <a id="ctl00_mainContent_gvSpecialEvent_ctl02_imbAgencyCode">{{item.agencyCode}}</a>
                </td>
                <td>
                  <a id="ctl00_mainContent_gvSpecialEvent_ctl02_imbContactName">{{item.contactName}}</a>
                </td>
                <td>
                  <a id="ctl00_mainContent_gvSpecialEvent_ctl02_imbContactPhone">{{item.contactPhone}}</a>
                </td>
                <td>
                  <a id="ctl00_mainContent_gvSpecialEvent_ctl02_imbAmount">{{item.amount | currency}}</a>
                </td>
                <td>
                  <a id="ctl00_mainContent_gvSpecialEvent_ctl02_imbEventDate">{{item.eventDate |date: 'dd/MM/yyyy'
                    }}</a>
                </td>
                <td>
                  <a id="ctl00_mainContent_gvSpecialEvent_ctl02_imbCreateDate">{{item.createDate | date:
                    'dd/MM/yyyy'}}</a>
                </td>
                <td>
                  <a id="ctl00_mainContent_gvSpecialEvent_ctl02_imbCreatedByEIN">{{item.createdByEIN}}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <input type="button" (click)="addNewSpecialEvent()" name="ctl00$mainContent$btnAdd" value="Add" id="ctl00_mainContent_btnAdd">

      </div>

      <div id="footer">
        <ul>
          <li><b>Version: 2.0.0 </b>:: <a href="/">SECC Home</a></li>
        </ul>
      </div>
    </div>
  </form>

  <span id="okta-plugin-message-channel-available" style="display: none"></span>
</body>

</html>
