import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SeccdonateService } from 'src/app/services/seccdonate.service';

@Component({
  selector: 'app-paymentmethod',
  templateUrl: './paymentmethod.component.html',
  styleUrls: ['./paymentmethod.component.css']
})
export class PaymentmethodComponent implements OnInit {


  paymentMethod : string;
  isPayrollEnabled : boolean;
  payrollDisabledReason : string;



  payrollEnabledSubscription : Subscription;

  constructor(public seccdonateService : SeccdonateService,
              private router : Router,
              private sessiontimeoutService : SessiontimeoutService
              ) { }

  ngOnInit() {

    this.sessiontimeoutService.reset();
    console.log(this.seccdonateService);
	  this.setPayrollEnabled(this.seccdonateService.contribId,this.seccdonateService.annualAmount);
  }


  private setPayrollEnabled(contribId : string, totalAmount : number) {

    //isPayrollEnabled() should not sent contrib Id from client.
    //TODO Leaving AS-IS but Server Side should be modified to read from session.
    this.payrollEnabledSubscription = this.seccdonateService.isPayrollEnabled(contribId, totalAmount).subscribe(

        result => {
		  let payrollStatus = result as any;
          this.isPayrollEnabled = payrollStatus.enabled; // .enabled is a boolean field equiv to isEnabled
		  this.payrollDisabledReason = payrollStatus.reason;
        },
        err => {
			console.log(err);
        }
    );
  }


  continue() {

    this.seccdonateService.paymentType = this.paymentMethod;

    this.router.navigate(['contributor/submission']);
  }

  goBack() {

    this.router.navigate(['contributor/contributions']);
  }


  ngOnDestroy(){

    if(this.payrollEnabledSubscription != undefined) this.payrollEnabledSubscription.unsubscribe();
  }


}
