import { AdminService } from './../../../services/admin.service';
import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DonationSummary } from './../../../models/donationsummary.model';
import { EmployeeidlookupComponent } from '../employeeidlookup/employeeidlookup.component';
import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';

import {MatTableModule, MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-adminhome',
  templateUrl: './adminhome.component.html',
  styleUrls: ['./adminhome.component.css']
})
export class AdminhomeComponent implements OnInit {


  //Employee lookup section
  //employeeID : string;
  isInvalidEmpId : boolean = false;

  private empSearchSubscription : Subscription = new Subscription();


  //Donation Summary section
  private donationSummarySubscription : Subscription = new Subscription();
  displayedColumns = ['dYear', 'prd', 'cc'];
  dataSource = new MatTableDataSource<DonationSummary>();


  constructor(public adminSvc : AdminService,
              private router : Router,
              private dialog : MatDialog,
              private sessiontimeoutService : SessiontimeoutService

            ) { }

  ngOnInit(): void {
    this.sessiontimeoutService.reset();
    this.isInvalidEmpId = false;
    this.getDonationSummaryAll();
  }


  //Lookup Employee from ActiveDirectory and create Contributor in Contributor table
  //if doesn't already exist
  getEmployee() {
    this.empSearchSubscription = this.adminSvc.searchEmployee(this.adminSvc.employeeEin).subscribe(
      result => {
          if(result==null || result=='') {
            this.isInvalidEmpId = true;
            return;
          }
          this.adminSvc.contributor = result;
          this.router.navigate(['admin/contributionentry']);
      },
      err => {
        console.log(err);
      }
    )

  }

  getDonationSummaryAll() {

      this.donationSummarySubscription = this.adminSvc.getDonationSummaryAll().subscribe(

        result => {
          this.adminSvc.donationSummaryList = result as Array<DonationSummary>;
        },
        err => {
          console.log(err);
        }
    );
  }


  showEmpIdLookupModal() {

    const dialogRef = this.dialog.open(EmployeeidlookupComponent, {
      width: 'auto',
      height: 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

    });
  }

  ngOnDestroy() {

    if(this.donationSummarySubscription != undefined) this.donationSummarySubscription.unsubscribe();
    if(this.empSearchSubscription != undefined) this.empSearchSubscription.unsubscribe();
  }


}
