import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import { Subscription } from 'rxjs';

import { Designation }  from '../../../models/designation.model';
import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';

@Component({
  selector: 'app-adminreceipt',
  templateUrl: './adminreceipt.component.html',
  styleUrls: ['./adminreceipt.component.css']
})
export class AdminreceiptComponent implements OnInit {


    designationSubscription : Subscription = new Subscription();


    contributions : Designation[]; // Contributions for which payment was made in the contribution entry page.
    total : Number;
    methodOfPayment : String;

    constructor(public adminSvc : AdminService) { }

    today : Date;

    ngOnInit(): void {

      this.today = new Date();
      this.methodOfPayment = this.getPaymentMethod();
      this.getDesignations();

    }

    getDesignations() {

      if(this.adminSvc.trxId=='') return;
      this.designationSubscription = this.adminSvc.getDesignationsNP(this.adminSvc.trxId).subscribe(
          (response : any) => {
              this.contributions = response ;
              console.log('Response: ');
              console.log(response);
              this.calcTotal();
           });
    }

    calcTotal() {

      let total : Number = 0;
      for(let item of this.contributions) {
        total = +total + +item.amount;
      }
      this.total = total;

    }

    getPaymentMethod() {

      var paymentType = this.adminSvc.contributionEntry.paymentType;
      if (paymentType == null)
        return "Unknown Payment Option";

              if (paymentType == "PRD") //payroll deduction
                  return "Automatic Payroll Deduction";
              else if (paymentType == "CA") // cash
                  return "Check / Cash";
              else if (paymentType == "EC") // eCheck
                  return "eCheck";
              else if (paymentType == "CC") //credit card
                  return "Credit / Debit Card";
              else
                  return '';
    }

  ngOnDestroy() {
    if(this.designationSubscription != undefined) this.designationSubscription.unsubscribe();
  }


}
