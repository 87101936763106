import { SessiontimeoutService } from '../../services/sessiontimeout.service';
import { SeccdonateService } from '../../services/seccdonate.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  constructor(private sessiontimeoutService: SessiontimeoutService,
              public seccdonateService: SeccdonateService
              ) { }

  fullNameSubscription : Subscription = new Subscription();

  ngOnInit() {

    this.sessiontimeoutService.reset();
    this.getFullName();
  }


  getFullName(){

    this.fullNameSubscription = this.seccdonateService.getUserFullName().subscribe(
      (response) => {
        let res = response as any;
        var fullName = res.fullName;
        this.seccdonateService.userFullName = fullName;
      });
  }

  ngOnDestroy(){
    if(this.fullNameSubscription != undefined) this.fullNameSubscription.unsubscribe();
  }

}
