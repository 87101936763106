import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SeccdonateService } from '../../services/seccdonate.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private router : Router, private seccdonateService : SeccdonateService) { }

  isLoginError : boolean = false;

  ngOnInit() {
    if(this.router.url.includes('error=true')) {
      this.isLoginError = true;
    }
  }

  showSpinner() {
    console.log('Starting spinner');
    var spinner = this.seccdonateService.startSpinner('spinarea-secc');
  }


}
