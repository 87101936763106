import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notyou',
  templateUrl: './notyou.component.html',
  styleUrls: ['./notyou.component.css']
})
export class NotyouComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
