<table id="ctl00_mainContent_DesignationTable" style="border: 0px; width: 100%">
  <tbody>


      <tr id={{nonProfitId}} style="border: 0px; width: 100%">
          <td  style="width:70%"><strong>{{nonProfitTitle}}</strong></td>
          <td  style="width:10%">$ </td>
          <td style="text-align: right; width:20%">
            <input id="{{nonProfitId}}" size="8" align="right" (blur)="calcTotal()" [(ngModel)]="amount" autocomplete="off" style="margin-top: 6px; background-color: rgb(255, 255, 255); text-align: right; border: 1px solid rgb(102, 102, 102); margin-left: 6px; float: right;">
          </td>
      </tr>
</tbody>
</table>
