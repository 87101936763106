import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';

import { BannerService } from 'src/app/services/banner.service';
import { Agency } from 'src/app/models/agency.model';
import { SpecialEventDetail } from 'src/app/models/specialeventdetail.model';
import { SpecialEventWithDetail } from 'src/app/models/specialeventwithdetail.model';
import { SeccdonateService } from 'src/app/services/seccdonate.service';

@Component({
  selector: 'app-editevent',
  templateUrl: './editevent.component.html',
  styleUrls: ['./editevent.component.css']
})
export class EditeventComponent implements OnInit {

  constructor(public bannerSvc : BannerService,
              public adminSvc : AdminService,
              private router : Router,
              private changeDetectorRef: ChangeDetectorRef)
   { }

  agenciesSubscription : Subscription = new Subscription();
  nonProfitsSubscription : Subscription = new Subscription();
  saveSpecialEventSubscription : Subscription = new Subscription();

  agencies : Agency[];
  selectedAgency : string;
  nonProfits : Array<any>;

  spdAddCount : number = 0;  //Number of Special Event Detail added in UI *before* persisting should not exceed 1 per legacy logic


  //UI messages





  ngOnInit(): void {

    this.bannerSvc.getDonationSummaryAll();
    this.getAgencies();
    this. getNonProfitAll() ;

  }

  ngAfterViewInit() {
    this.initActionPanel();
  }


  initForm() {
      console.log(this.adminSvc.selectedSpecialEvent);
      this.selectedAgency = this.adminSvc.selectedSpecialEvent.specialEvent.agencyCode;
      //..more
  }

  onDateInput(event: MatDatepickerInputEvent<Date>) {

    this.adminSvc.selectedSpecialEvent.specialEvent.eventDate = event.value?event.value : new Date();
  }


   getNonProfitAll() {
    this.nonProfitsSubscription = this.adminSvc.getAllNonProfitsAll().subscribe (

      result => {
        this.nonProfits = result as Array<any>;
        console.log(this.nonProfits);
      },
      err => {
        console.log(err);
      }
    )
   }

   switchToEditMode(item: SpecialEventDetail, idx : Number) {

      console.log('idx=');
      console.log(idx);
        // In EDIT mode, the edit button panel itself will be hidden and cancel button panel displayed.
        const editbtn = document.getElementById('seDetail_editbtn_' + idx.toString()) as HTMLElement;
        editbtn.hidden = true;

        const cancelbtn = document.getElementById('seDetail_cancelbtn_' + idx.toString()) as HTMLElement;
        cancelbtn.hidden = false;

        //Enable text box for amount
        const amountElement = document.getElementById('seDetail_amount_' + idx.toString()) as HTMLInputElement;
        amountElement.disabled = false;

        //Hide current NonProfit box
        const currNonProfit = document.getElementById('seDetail_currNonProfit_' + idx.toString()) as HTMLElement;
        currNonProfit.hidden = true; //check if hidden is right property for this.

        //...Show NonProfit dropdown, with current NP pre selected
        const selectedNonProfit = document.getElementById('seDetail_selectedNonProfit_' + idx.toString()) as HTMLElement;
        selectedNonProfit.hidden = false; //check if hidden is right property for this.

        //Prepopulate dropdown
        const npDropDown = document.getElementById('np_dropDown_' + idx.toString()) as HTMLSelectElement;
        npDropDown.value = this.adminSvc.selectedSpecialEvent.specialEventDetailList[idx.valueOf()].nonProfitID;

   }

   cancelEdit(item: SpecialEventDetail, idx : number) {

        // If cancelling a row newly added via the UI, treat it as a delete operation
        if(this.adminSvc.selectedSpecialEvent.specialEventDetailList[idx].id=='') {
          this.delete(item, idx) ;
          return;
        }



        //1. Display Edit button (again)
        const editbtn = document.getElementById('seDetail_editbtn_' + idx.toString()) as HTMLElement;
        editbtn.hidden = false;

        //2. Hide Cancel button
        const cancelbtn = document.getElementById('seDetail_cancelbtn_' + idx.toString()) as HTMLElement;
        cancelbtn.hidden = true;

        //3.
        const amountElement = document.getElementById('seDetail_amount_' + idx.toString()) as HTMLInputElement;
        //Revert amount and disable box
        amountElement.value = this.adminSvc.selectedSpecialEvent.specialEventDetailList[idx.valueOf()].amount.toString();
        amountElement.disabled = true;


        //4. Hide non profit dropdown
        const selectedNonProfit = document.getElementById('seDetail_selectedNonProfit_' + idx.toString()) as HTMLElement;
        selectedNonProfit.hidden = true; //check if hidden is right property for this.

        //5. Show current nonProfit.
        const currNonProfit = document.getElementById('seDetail_currNonProfit_' + idx.toString()) as HTMLElement;
        currNonProfit.hidden = false; //check if hidden is right property for this.

   }

   //Save selected nonProfit to service
   saveSEDetail(item: SpecialEventDetail, idx : Number) {


      //Update Svc with new value of NP
      const npDropDown = document.getElementById('np_dropDown_' + idx.toString()) as HTMLSelectElement;
      this.adminSvc.selectedSpecialEvent.specialEventDetailList[idx.valueOf()].nonProfitID = npDropDown.value ;
      this.adminSvc.selectedSpecialEvent.specialEventDetailList[idx.valueOf()].npTitle =
                                                            npDropDown.options[npDropDown.selectedIndex].text;


      //Update Svc with new value of Amount
      const amountElement = document.getElementById('seDetail_amount_' + idx.toString()) as HTMLInputElement;
      //Revert amount and disable box
      this.adminSvc.selectedSpecialEvent.specialEventDetailList[idx.valueOf()].amount =  +amountElement.value;
      amountElement.disabled = true;



      //Switch panel back to show edit buttons
      const editbtn = document.getElementById('seDetail_editbtn_' + idx.toString()) as HTMLElement;
      editbtn.hidden = false;
      const cancelbtn = document.getElementById('seDetail_cancelbtn_' + idx.toString()) as HTMLElement;
      cancelbtn.hidden = true;

      //3. Hide NonProfit dropdown
      const selectedNonProfit = document.getElementById('seDetail_selectedNonProfit_' + idx.toString()) as HTMLElement;
      selectedNonProfit.hidden = true; //check if hidden is right property for this.

       //4. Show current(=NEW) nonProfit.
       const newNonProfit = document.getElementById('seDetail_currNonProfit_' + idx.toString()) as HTMLElement;
       newNonProfit.hidden = false; //check if hidden is right property for this.

   }

   addNew() {

    if(this.spdAddCount >= 1) {  //Can add no more than 1 before persisting to db.
      return ;
    }
    var sed : SpecialEventDetail = new SpecialEventDetail() ;
    this.adminSvc.selectedSpecialEvent.specialEventDetailList.push(sed);
    this.changeDetectorRef.detectChanges(); //Force a redraw of the table so dom can be manipulated without issues.

    ++this.spdAddCount;   //Reset to 0 after persisting to db

    var lastIdx : number = this.adminSvc.selectedSpecialEvent.specialEventDetailList.length -1;
    this.switchToEditMode(null, lastIdx);

    console.log( this.adminSvc.selectedSpecialEvent.specialEventDetailList);
   }

  delete(item: SpecialEventDetail, idx : number) {

    if(this.adminSvc.selectedSpecialEvent.specialEventDetailList[idx].id=='') { //If deleted row was a newly added one.
      --this.spdAddCount;
    }
    this.adminSvc.selectedSpecialEvent.specialEventDetailList.splice(idx, 1);
    this.changeDetectorRef.detectChanges();
  }

   //Initialize the edit/cancel buttons  panel. Initial state is the same
   //as after cancel has been clicked.
   initActionPanel() {

    for (var i = 0;
          i < this.adminSvc.selectedSpecialEvent.specialEventDetailList.length;i++ )
    {
        this.cancelEdit(null, i);
    }

   }



  goBack() {
    this.router.navigate(['admin/specialevents/list']);
  }

  saveEdit() {

    if(this.isAmountMismatch()) {
      return;
    }

    var specialEventWithDetail : SpecialEventWithDetail = this.adminSvc.selectedSpecialEvent;

    this.saveSpecialEventSubscription = this.adminSvc.saveSpecialEvent(specialEventWithDetail).subscribe(

          response => {
              this.router.navigate(['admin/specialevents/list']);
            },
          err => {
            console.log(err);
          }

        )
  }

  calcTotal() : number{

    //adminSvc.selectedSpecialEvent.specialEventDetailList
    var amountTotal : number = 0;
    this.adminSvc.selectedSpecialEvent.specialEventDetailList.forEach ( item => {

      amountTotal += item.amount;
    })
    return amountTotal;
  }

  //UI Messages
  isAmountMismatch() : boolean {

    return this.calcTotal() != this.adminSvc.selectedSpecialEvent.specialEvent.amount;
  }

  getAgencies() {
    this.agenciesSubscription = this.adminSvc.getAllAgencies().subscribe(response => {
      this.agencies = response as Array<Agency>;
      this.initForm(); // Initialize form here
    }
    ), (err => {
      console.log(err);
    })
  }


  ngOnDestroy() {

    if (this.agenciesSubscription != undefined) this.agenciesSubscription.unsubscribe();
    if (this.nonProfitsSubscription != undefined) this.nonProfitsSubscription.unsubscribe();
  }

}
