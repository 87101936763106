import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router} from '@angular/router';
import { SeccdonateService } from '../../services/seccdonate.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  constructor(public seccdonateService : SeccdonateService,
              private router : Router,
              private sessiontimeoutService : SessiontimeoutService
              ) { }


  historySubscription  : Subscription = new Subscription();
  fullNameSubscription : Subscription = new Subscription();
  private contributerSubscription : Subscription = new Subscription();
  designationTrxList : any = {};

  isShowReadyToDonate : boolean = false;

  isShowLoading : boolean = false;

  spinner : any;


  ngOnInit() {

    this.spinner = this.seccdonateService.startSpinner('spinarea-secc');

    this.sessiontimeoutService.reset();
    this.getFullName();
    this.getContributor(''); //remove ein from function signature since not required
    this.getDonationHistory();
  }

  getDonationHistory() {

    this.isShowLoading = true;
    console.log('Starting get donation history');
    this.historySubscription = this.seccdonateService.getDonationHistory().subscribe(

      result => {
        console.log('Complete Donation History : ');
        console.log(result);
        this.seccdonateService.stopSpinner(this.spinner);
        this.designationTrxList = result;  //TODO replace this with single global instance below
        this.seccdonateService.designationTrxHistory = result;
        this.isShowLoading = false;
       // this.setReadyToDonateVisibility();
      },
      err => {
      }
  );
}

/*
This functionality was already implemented in the Welcome page. However for some
reason Angular is injecting a fresh instance of the SeccdonateService class
into Welcome component instead of the Singleton instance. This instance is not
shared by other components. This function is being duplicated here as a result.
The issue is not a showstopper however. Remove if the Singleton issue is resolved.

*/

getFullName(){

  this.fullNameSubscription = this.seccdonateService.getUserFullName().subscribe(
    (response) => {
      let res = response as any;
      var fullName = res.fullName;
      this.seccdonateService.userFullName = fullName;
      this.seccdonateService.campaignYear = +res.campaignYear;
    });
}


  // If any of the donations in history is for the campaign year,
  // turn visibility OFF. Donation already made.
  isReadyToDonateVisible() : boolean {

    this.seccdonateService.designationTrxHistory.forEach( c => {
      if( +(c.participantYear) == this.seccdonateService.campaignYear ) {
        return false;
      }
    })
    return true; //default
  }




//ein NOT needed.
getContributor(ein : string) {
  this.contributerSubscription = this.seccdonateService.getContributor(ein).subscribe(
    result => {
      let apiresponse : any = result;
      this.seccdonateService.contributor = result;
    },
    err => {
      console.log(err);
    }
  );
}

  showReceipt(trxId : string, year : number, paymentType : string) {

    this.seccdonateService.dispReceiptTrx = trxId;
    this.seccdonateService.participantYear = year;
    this.seccdonateService.paymentType = paymentType;
    this.seccdonateService.isFromHistory = true;
    this.router.navigate(['contributor/receipt'], {queryParams: { reload: Math.random() }}); //force reload
  }

  /*
  ngOnDestroy(){

    if(this.historySubscription != undefined) this.historySubscription.unsubscribe();
    if(this.fullNameSubscription != undefined) this.fullNameSubscription.unsubscribe();
    if (this.contributerSubscription != undefined) this.contributerSubscription.unsubscribe();
  }
*/

}
