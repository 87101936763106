import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';




@Component({
  selector: 'app-idletimeoutdialog',
  templateUrl: './idletimeoutdialog.component.html',
  styleUrls: ['./idletimeoutdialog.component.css']
})
export class IdletimeoutdialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<IdletimeoutdialogComponent>,
    public dialog: MatDialog,
    private sessiontimeoutService : SessiontimeoutService
    ) { }

  clockDisplay : string = "";

  ngOnInit(): void {

    var time : number = this.sessiontimeoutService.TIMEOUT ;
    var minutes : number = Math.floor(time/60);
    var seconds : number = Math.floor(time - minutes * 60);
    this.clockDisplay =  minutes.toString() + ":" + (seconds < 10 ? seconds.toString()+"0":seconds.toString());  //initialize countdown display

    this.displayTimer();
  }

  close(): void {
    this.dialogRef.close();
  }


  //Countdown Timer
  displayTimer() {

      var timeout : number = this.sessiontimeoutService.TIMEOUT;   //timeout in seconds
      var timerEndTime = (new Date().getTime()) + (timeout * 1000);

        // Update the count down every 1 second
        var x = setInterval(() => {
              var now = new Date().getTime();
              var distance = timerEndTime - now;

              // If the count down is over, write some text
              if (distance <= 0) {
                clearInterval(x);
                this.close();
                return;
              }
              var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
              var seconds = Math.floor((distance % (1000 * 60)) / 1000);
              this.clockDisplay = minutes + ":" + seconds ;

            }, 1000);
  }

}
