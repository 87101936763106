<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  <title>
    State Employees Charitable Giving Campaign
  </title>
</head>

<body>


  <form name="aspnetForm" id="orderForm">
    <div>

    </div>





    <script
      src="/Donate/ScriptResource.axd?d=JzRgHnntRiRn-STHqvLn82DMYuzaEBa0XCsS7iTI9X1iFCMVyqFDRpSpIubPKLMa140NlX7HLexgWwz171jV8Dpa3JpF_8J1oJwCWNTee6ByxnGTEyrN6G32ARLRkKbMebP1SrL9i3hZv_MBBZ7giq3UBiN5qoAQnoYdiNGWT0g1&amp;t=14d69f50"
      type="text/javascript"></script>
    <script
      src="/Donate/ScriptResource.axd?d=dsj5ACFzcBEGRMZL6hrpaW7FP-xanaiRs0mQ6-JSUvXhX2gbyyaij3ZaTyoI5ZisZTuU8txuqE5eq-r5IkSotnHDh0WeqFM6XQlKO-qdy-bgqc8hFT0MHIc-Kj48AjuNYEGvXvPOVfnTOfkyFrzEWg2&amp;t=ffffffffcda39869"
      type="text/javascript"></script>


    <div>
    </div>
    <div>
      <span id="dirtyForm" display="none"></span>
      <span id="ctl00_Label1"></span>

    </div>
    <div id="wrapper">
      <div id="header">
        <h1><a href="default.aspx">Arizona State Employee Charitable Campaign</a></h1>
        <img id="ctl00_imgHeader" src="assets/img/secc.png" alt="Arizona State Employee Charitable Campaign"
          style="border-width:0px;">
      </div>
      <div id="nav">

        <ul>
          <li>
            <a id="ctl00_LoginView1_LoginStatus1" title="Logout" href="contributor/auth/dologout">LOGOUT</a>
          </li>
          <!--<li><a href="feedback.aspx" title="Feedback">FEEDBACK</a></li>//-->
          <li><a href="assets/doc/Instructions.pdf" title="Instructions" target="_blank">INSTRUCTIONS</a></li>
          <li><a href="contributor/welcome" title="Home">HOME</a></li>
        </ul>

      </div>
      <div id="container">

      <span *ngIf="seccdonateService.paymentType=='PRD'">
        <h2> Paycheck Deduction Amount (each of 26 pay periods):<strong> {{ seccdonateService.annualAmount / 26 | currency}} </strong></h2>
      </span>

        <h3>
          Contributions for {{this.seccdonateService.annualAmount | currency}}
        </h3>


        <br>
        <br>
        <div>
          At SECC, We know there are many ways to help. If you do any volunteer work for non-profits,
          please let us know.
          <br>
          I volunteer&nbsp;
          <input name="volunteerHours" type="number" [(ngModel)]="volunteerHours" maxlength="2" id="volunteerHours"
            style="width:40px;">&nbsp;
          hours per month.
          <br>
          <span id="ctl00_mainContent_revtxtVolunteerHours" style="color:Red;visibility:hidden;">Please Enter
            Numbers.</span>
          <br>
          <br>
        </div>

        <br>
        <br>


        <span id="ctl00_mainContent_lblError" style="color:Red;"></span>
        <div id="spinarea-secc"></div>
        <div id="ctl00_mainContent_dvSubmit">
          <div align="center" style="margin-top: 10px;">

            <div id="options">
              <h4>
                I understand that selecting the "Submit" button on this page will
                <br>
                create a contribution with the above payment method.
              </h4>
              <input value="Agree" name="create_contribution_pledge" type="radio" id="create_contribution_pledge_agree"
                value="agree" [(ngModel)]="createContributionPledge">I
              Agree
              <input value="Disagree" name="create_contribution_pledge" type="radio"
                id="create_contribution_pledge_disagree" value="disagree" [(ngModel)]="createContributionPledge">I
              Disagree<br>
              <span *ngIf="isCreateContributionPledgeReminder">
                <font size='1' color="red">You must agree to continue</font>
              </span>
              <br>
              <div id="ctl00_mainContent_Message" class="reqd">
              </div>
              (You must Agree to Continue)
            </div>
            <div id="options" style="text-align: left;overflow-wrap: break-word;" *ngIf="seccdonateService.paymentType=='CC'">
              <span style="color: Red"><b>Note:</b> You will be redirected to an external site (Paypal) for payment. After
                payment you will be redirected back to SECC. Please DO NOT close browser
                window until you are back and see payment confirmation.</span>

            </div>
            <p>
              <button name="editContributionsButton" id="editContributionsButton" (click)="editContributions()">Edit
                Contributions</button>
              <button name="backButton" id="backButton" (click)="back()">Back</button>
              <button name="submitButton" id="submitButton" (click)="submit()">Submit</button>
            </p>
          </div>


        </div>

      </div>
      <div id="footer">
        <table width="100%">
          <tbody>
            <tr>
              <td width="47%"><a href="mailto:Linda.Stiles@azdoa.gov" id="ctl00_aDirector"
                  target="_blank">Linda.Stiles@azdoa.gov</a>
              </td>
              <td>
                <a href="default.aspx">SECC Home</a>
              </td>
              <td style="text-align: right; font-weight: bold;">
                Version: 2.0.0
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </form>


  <div class="ui-dialog ui-widget ui-widget-content ui-corner-all  ui-draggable ui-resizable" tabindex="-1"
    role="dialog" aria-labelledby="ui-dialog-title-ctl00_Timeout1"
    style="display: none; position: absolute; overflow: hidden; z-index: 1000; outline: 0px;">
    <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix" unselectable="on"><span
        class="ui-dialog-title" id="ui-dialog-title-ctl00_Timeout1" unselectable="on">Session Expiring</span><a href="#"
        class="ui-dialog-titlebar-close ui-corner-all" role="button" unselectable="on"><span
          class="ui-icon ui-icon-closethick" unselectable="on">close</span></a></div><span id="ctl00_Timeout1" style=""
      class="ui-dialog-content ui-widget-content">
      <p>
        <span class="ui-icon ui-icon-alert" style="float: left; margin: 1px 10px 20px 0;">
        </span>Your session will expire in.
      </p>
      <span id="tscCounter"></span>&nbsp;seconds
      <p>
        Click <b>OK</b> to continue your session.</p>
    </span>
    <div class="ui-resizable-handle ui-resizable-n" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-e" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-s" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-w" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se ui-icon-grip-diagonal-se"
      unselectable="on" style="z-index: 1001;"></div>
    <div class="ui-resizable-handle ui-resizable-sw" unselectable="on" style="z-index: 1002;"></div>
    <div class="ui-resizable-handle ui-resizable-ne" unselectable="on" style="z-index: 1003;"></div>
    <div class="ui-resizable-handle ui-resizable-nw" unselectable="on" style="z-index: 1004;"></div>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix"><button type="button"
        class="ui-state-default ui-corner-all">Ok</button></div>
  </div>
</body>
