import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { AdminService } from 'src/app/services/admin.service';
import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { BannerService } from 'src/app/services/banner.service';
import { SpecialEventByEventNum } from 'src/app/models/specialeventbyeventnum.model';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-specialeventreport',
  templateUrl: './specialeventreport.component.html',
  styleUrls: ['./specialeventreport.component.css']

})
export class SpecialeventreportComponent implements OnInit {

  spEventsRptSubscription: Subscription = new Subscription();

  splEvents: SpecialEventByEventNum[][];
  constructor(private adminSvc: AdminService,
    public bannerSvc: BannerService,
    private sessiontimeoutService: SessiontimeoutService

  ) { }


  //Pagination
  currentPage = 0;
  totalPages: number;
  currentPageData: SpecialEventByEventNum[][];



  ngOnInit(): void {


    this.sessiontimeoutService.reset();
    this.bannerSvc.getDonationSummaryAll();

    this.getSpecialEventReport();
  }



  //Pagination
  onPageChange($event) {
    this.setCurrentPage($event.page);
  }

  //Populate page data for the specified page
  setCurrentPage(currentPage: number) {
    this.currentPageData = this.splEvents.slice(currentPage * 3, (currentPage + 1) * 3);
    console.log('setting current page with data');
    console.log(this.currentPageData);
  }



  getSpecialEventReport() {

    this.adminSvc.getSpecialEventReport().subscribe(
      response => {

        this.splEvents = response as SpecialEventByEventNum[][];
        this.totalPages = Math.ceil(this.splEvents.length / 3); // Calculate total pages
        this.setCurrentPage(0); // init to first page
        console.log(this.splEvents);

        //TEST
        this.reformatSplEventsWithDonations();


      },
      err => {
        console.log(err);
      });

  }


  generatePdf() {


    const doc = new jsPDF();

    //Insert Page Header "Special Events Report"
    let tableData: Array<Map<string, any>> = new Array<Map<string, any>>();

    var splEventsReformatted = this.reformatSplEventsWithDonations();

    splEventsReformatted.forEach(splEvent => {
      let tableData: string[][];
      tableData = splEvent.map(item => [item.specialEventNo,
      item.agencyTitle,
      item.contactName,
      item.contactPhone,
      (new Date(item.eventDate)).toLocaleDateString('en-US'),
      '$' + item.amount
      ]
      );

      autoTable(doc, {
        head: [['Special Event No', 'Agency Title', 'Contact Name', 'Contact Phone', 'Event Date', 'Amount']],
        body: tableData,
      });

    });

    doc.save('rptSpecialEvent.pdf');

  }


  generateExcel() {

    var splEventsReformatted = this.reformatSplEventsWithDonations();

    //Remove field 'donations' so it doesn't appear in spreadsheet
    splEventsReformatted.forEach( item => {
                                    item.forEach( x=>{ delete x.donations;});
                                });


    var allSpecialEvents: SpecialEventByEventNum[] =
      splEventsReformatted.reduce((acc, val) => acc.concat(val), []); //Reduce 2D array to 1D array ('flatten')

    console.log('1D array');
    console.log(allSpecialEvents);
    const worksheet = XLSX.utils.json_to_sheet(allSpecialEvents);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    // Generate Excel buffer
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Save the file
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const blobName = 'rptSpecialEvents.xlsx';
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = blobName;
    link.click();


  }

  /*
  Extract the donations sublist inside SpecialEventByEventNum[] and insert into main list
  */
  reformatSplEventsWithDonations() {

    var origArray: SpecialEventByEventNum[][] = this.splEvents;
    var newArray: SpecialEventByEventNum[][] = [];

    origArray.forEach(item => {

      // newArray.push(item);  //NO. Push an array of len > 1
      var container: SpecialEventByEventNum[] = [];

      item.forEach(subitem => {       //Only 1 element in every 'item'

        item[0].eventDate = (new Date(item[0].eventDate)).toLocaleDateString('en-US'); //format date to standard

        container.push(item[0]);  //original element
        subitem.donations.forEach(don => {

          var elem: SpecialEventByEventNum = new SpecialEventByEventNum();
          elem.specialEventNo = "";
          elem.agencyTitle = don.nonProfitTitle;
          elem.contactName = "";
          elem.contactPhone = "";
          elem.eventDate = (new Date(item[0].eventDate)).toLocaleDateString('en-US');  // (new Date(item.eventDate)).toLocaleDateString('en-US'),
          elem.amount = don.charityAmount;
          container.push(elem);
        })

      })

      newArray.push(container);
    });
    return newArray;
  }



  ngOnDestroy() {
    if (this.spEventsRptSubscription != undefined) this.spEventsRptSubscription.unsubscribe();

  }



}
