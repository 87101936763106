<head id="ctl00_Head1">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <title>SECC Online Donations</title>
</head>

<body>
  <form name="aspnetForm" method="post" action="" id="aspnetForm">
    <div></div>

    <div></div>

    <div>
      <span id="dirtyForm" display="none"></span>
      <span id="ctl00_Label1"></span>
      <span id="ctl00_Timeout1" title="Session Expiring" style="display: none">
        <p>
          <span
            class="ui-icon ui-icon-alert"
            style="float: left; margin: 1px 10px 20px 0"
          >
          </span
          >Your session will expire in.
        </p>
        <span id="tscCounter"></span>&nbsp;seconds
        <p>Click <b>OK</b> to continue your session.</p>
      </span>
    </div>
    <div id="wrapper">
      <div id="header">
        <img
          id="ctl00_imgHeader"
          src="assets/img/header.jpg"
          alt="SECC Header and Logo"
          style="height: 190px; width: 890px; border-width: 0px"
        />
      </div>
      <div id="nav">
        <ul>
          <li>
            <a
              id="ctl00_LoginView1_LoginStatus1"
              title="Logout"
              href="admin/auth/dologout"
              >LOGOUT</a
            >
          </li>

          <li>
            <a id="ctl00_LoginView1_hlHome" title="Home"  href="admin/home"
              >HOME</a
            >
          </li>
        </ul>
      </div>
      <div>
        <table width="100%" border="1">
          <tbody>
            <tr style="background-color: Aqua">
              <td>
                <div id="ctl00_LoginView2_UpdatePanel1">
                  <div style="width: 100%; height: 28px">
                    <div
                      id="ctl00_LoginView2_timer"
                      style="border-style: Solid"
                    >
                      <div
                        style="
                          width: 100%;
                          height: 100%;
                          vertical-align: middle;
                          text-align: center;
                        "
                      >
                        <span
                          id="ctl00_LoginView2_currentTime"
                          style="font-size: large; font-weight: bold"
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;Payroll Deduction: 668422.72
                          &nbsp;&nbsp;&nbsp;&nbsp;One Time Payments/Special
                          Events: 23645.00</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="container">
        <div id="container">
          <h2>
            Enter Contributions For Employee {{ contributionEntry.contributor.firstName }}
            {{ contributionEntry.contributor.lastName }}
          </h2>
          <table
            style="border: 0px; border-style: solid; border-color: #d3dce8"
          >
            <tbody>
              <tr>
                <td align="right" style="width: 100px">Address:</td>
                <td colspan="3">
                  <input
                    name="ctl00$mainContent$Address"
                    type="text"
                    id="ctl00_mainContent_Address"
                    maxlength="50"
                    style="width: 288px"
                    [(ngModel)]="contributionEntry.contributor.address"
                  />
                </td>
              </tr>
              <tr>
                <td align="right" style="width: 100px">City:</td>
                <td style="width: 96px">
                  <input
                    name="ctl00$mainContent$City"
                    type="text"
                    id="ctl00_mainContent_City"
                    maxlength="50"
                    style="width: 172px"
                    [(ngModel)]="contributionEntry.contributor.city"
                  />
                </td>
                <td style="width: 44px">State:</td>
                <td style="width: 46px">
                  <input
                    name="ctl00$mainContent$State"
                    type="text"
                    id="ctl00_mainContent_State"
                    maxlength="50"
                    style="width: 28px"
                    [(ngModel)]="contributionEntry.contributor.state"
                  />
                </td>
              </tr>
              <tr>
                <td align="right" style="width: 100px">Zip Code:</td>
                <td style="width: 96px">
                  <input
                    name="ctl00$mainContent$ZipCode"
                    type="text"
                    id="ctl00_mainContent_ZipCode"
                    maxlength="50"
                    style="width: 174px"
                    [(ngModel)]="contributionEntry.contributor.zip"
                  />
                </td>
                <td style="width: 44px"></td>
                <td style="width: 46px"></td>
              </tr>
            </tbody>
          </table>
          <h2>Designations</h2>
          <table cellpadding="2" cellspacing="2" width="100%">
            <tbody>
              <tr>
                <td style="width: 25%">
                  <p>
                    Campaign Week&nbsp;
                    <select
                      [(ngModel)]="contributionEntry.campaignWeek"
                      name="ctl00$mainContent$weekList"
                      id="ctl00_mainContent_weekList"
                    >
                      <option selected="selected" value="-1">N/A</option>
                      <option value="1">Week 1</option>
                      <option value="2">Week 2</option>
                      <option value="3">Week 3</option>
                      <option value="4">Week 4</option>
                      <option value="5">Week 5</option>
                      <option value="6">Week 6</option>
                    </select>
                  </p>
                </td>
                <td style="width: 25%">
                  <p>
                    Volunteer Hours&nbsp;
                    <input
                      [(ngModel)]="contributionEntry.volHours"
                      name="ctl00$mainContent$txtVolunteerHours"
                      type="text"
                      maxlength="2"
                      id="ctl00_mainContent_txtVolunteerHours"
                      style="width: 40px"
                    />
                  </p>
                </td>
                <td style="width: 40%">
                  <p>
                    Pledge Date&nbsp;
                    <mat-form-field>
                      <input
                        matInput
                        [value]="contributionEntry.pledgeDate"
                        [matDatepicker]="pledgeDatepicker"
                        (dateInput)="onDateInput($event)"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="pledgeDatepicker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pledgeDatepicker></mat-datepicker>
                    </mat-form-field>
                  </p>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <span
                    id="ctl00_mainContent_revtxtVolunteerHours"
                    style="color: Red; visibility: hidden"
                    >Please Enter Numbers.</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <table
            style="border: 0px; border-style: solid; border-color: #d3dce8"
          >
            <tbody>
              <tr align="center" valign="bottom">
                <td width="100">
                  <strong
                    >Nonprofit<br />
                    Code</strong
                  >
                </td>
                <td width="110">
                  <strong
                    >Annual
                    <br />
                    Amount</strong
                  >
                </td>
                <td width="40" align="left">
                  <strong
                    >Send<br />
                    info</strong
                  >
                </td>
                <td width="50" align="left">
                  <strong
                    >Notify<br />
                    org</strong
                  >
                </td>
              </tr>
            </tbody>
          </table>
<!--
           id="NonProfitID1"
  -->
          <table
            id="ctl00_mainContent_DesignationTable"
            style="border: 0px; width: 300px"
          >
            <tbody>
              <tr *ngFor="let desig of contributionEntry.designations; let i = index"> <!--<<tr id="ctl00_mainContent_Des1">-->

                  <td>
                    <input
                      type="text"
                      [(ngModel)]="contributionEntry.designations[i].nonProfitCode"
                      id="NonProfitCode-{{i}}"
                      name="NonProfitCode-{{i}}"
                      size="5"
                    />
                  </td>
                  <td>$</td>
                  <td>
                    <input
                      type="text"
                      [(ngModel)]="contributionEntry.designations[i].amount"
                      id="Amount-{{i}}"
                      name="Amount-{{i}}"
                      size="6"
                      value="0"
                      (blur)="calculateTotal();"
                    />
                  </td>
                  <td>
                    <input type="checkbox" [(ngModel)]="contributionEntry.designations[i].sendInfo" id="sendInfo-{{i}}" name="sendInfo-{{i}}" />
                  </td>
                  <td>
                    <input type="checkbox" [(ngModel)]="contributionEntry.designations[i].notifyRecipient" id="notifyOrg-{{i}}" name="notifyOrg-{{i}}" />
                  </td>
                </tr>

            </tbody>
          </table>

          <p>
            <strong>TOTAL: {{contributionEntry.total | currency}}</strong>
            <input
              name="ctl00$mainContent$totalcontribution"
              type="text"
              id="ctl00_mainContent_totalcontribution"
              size="15"
              style="border: 0px"
              readonly=""
            />
          </p>
          <div id="getrowsbutton">
            <input
              type="button"
              (click)="addDesignationRow()"
              value="Add another row"
            />
          </div>
          <h2>Payment Method</h2>
          <input
            value="PRD"
            [(ngModel)]="contributionEntry.paymentType"
            name="ctl00$mainContent$radiobutton"
            type="radio"
            id="ctl00_mainContent_radioPRD"
            [disabled]="! isPayrollEnabled"
          />
          <strong>Payroll Deduction:</strong> Amount is divided into 26 equal
          paycheck deductions<br />

          <input
            value="CA"
            [(ngModel)]="contributionEntry.paymentType"
            name="ctl00$mainContent$radiobutton"
            type="radio"
            id="ctl00_mainContent_radioCASH"
          />
          <strong>Cash / Paper Check:</strong> One-time payment for full
          amount<br />
          <br />
          <div id="ctl00_mainContent_Message" class="reqd"></div>
          <br />
          <p>
            <input
              name="ctl00$mainContent$btnSubmit"
              type="button"
              id="ctl00_mainContent_btnSubmit"
              value="Submit Contribution"
              (click)="saveContributions()"
            />
          </p>
          <input
            name="ctl00$mainContent$lblSuccess"
            type="text"
            id="ctl00_mainContent_lblSuccess"
            style="color: green; font-weight: bold; border: 0px; width: 200"
            readonly="readonly"
          />
        </div>
      </div>
      <div id="footer">
        <ul>
          <li><b>Version: 2.0.0 </b>:: <a href="">SECC Home</a></li>
        </ul>
      </div>
    </div>

  </form>

  <span id="okta-plugin-message-channel-available" style="display: none"></span>
</body>
