import { SpecialEventDetail } from "./specialeventdetail.model";


export class SpecialEvent {

  id : string;
  agencyCode: string;
  amount: number;
  contactName: string;
  contactPhone: string;
  createDate: Date;
  createdByEIN: string;
  eventDate: Date;
  participantYear: number;
  specialEventNo: string;

  constructor() {
    this.id = '';
    this.id = '' ;
    this.agencyCode = '' ;
    this.amount = 0 ;
    this.contactName = '' ;
    this.contactPhone = '' ;
    this.createdByEIN = '' ;
    this.participantYear = 0 ;
    this.specialEventNo = '' ;

  }
}
