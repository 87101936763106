<html xmlns="http://www.w3.org/1999/xhtml">

<head id="ctl00_Head1">

  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  <title>
    SECC Online Donations
  </title>
</head>

<body>

  <form ngNoForm name="adminLoginForm" method="post" action="admin/auth/dologin" id="adminLoginForm">
    <div>
    </div>

    <div>

    </div>


    <div>
      <span id="dirtyForm" display="none"></span>
      <span id="ctl00_Label1"></span>
      <span id="ctl00_Timeout1" title="Session Expiring" style="display:none;">
        <p>
          <span class="ui-icon ui-icon-alert" style="float: left; margin: 1px 10px 20px 0;">
          </span>Your session will expire in.
        </p>
        <span id="tscCounter"></span>&nbsp;seconds
        <p>
          Click <b>OK</b> to continue your session.</p>
      </span>
    </div>
    <div id="wrapper">
      <div id="header">

        <img id="ctl00_imgHeader" src="assets/img/header.jpg" alt="SECC Header and Logo"
          style="height:190px;width:890px;border-width:0px;">

      </div>
      <div id="nav">

      </div>
      <div>

      </div>
      <div id="container">

        <div>
          <br>

          <table cellspacing="0" cellpadding="0" height="100%" align="center" border="0" style="border-color: #C0C0C0;
            border-width: 1px; border-style: solid;">
            <tbody>
              <tr>
                <td>
                  <table id="ctl00_mainContent_Login1" cellspacing="0" cellpadding="0" border="0"
                    style="border-collapse:collapse;">
                    <tbody>
                      <tr>
                        <td>
                          <table cellspacing="0" cellpadding="2" border="0" bgcolor="#ffffff">
                            <tbody>
                              <tr height="30">
                                <td align="center" colspan="2" class="LoginHeader"
                                  style="color: White; background:Navy;  font-size: 2em;">
                                  SECC Admin Login
                                </td>
                              </tr>

                              <tr>
                                <td colspan="5" height="1" bgcolor="black">
                                </td>
                              </tr>
                              <!--Login error message-->
                              <tr *ngIf="isLoginError">
                                <td>
                                  <font color="red">Your login attempt was not successful. Please try again.</font>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <table cellspacing="2" cellpadding="2" border="0" width="100%">
                                    <tbody>
                                      <tr>
                                        <td colspan="5" valign="top" class="ErrorMessage" align="center">

                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="5" height="15">
                                          <img height="1" src="images/gif.gif" width="1">
                                        </td>
                                      </tr>
                                      <tr>
                                        <td id="ctl00_mainContent_Login1_tdLock" rowspan="2" width="60" valign="middle"
                                          align="center">
                                          <img id="ctl00_mainContent_Login1_imgLock" src="assets/img/loginLogo.png"
                                            alt="SECC Image" style="height:100px;width:150px;border-width:0px;">
                                        </td>

                                        <td align="left" valign="top">
                                          <label for="ctl00_mainContent_Login1_UserName"
                                            id="ctl00_mainContent_Login1_UserNameLabel">EIN:</label>
                                        </td>
                                        <td valign="top" align="left">
                                          <input type="text" maxlength="9" id="username" name="username"
                                            style="width:150px;">
                                          <span id="ctl00_mainContent_Login1_UserNameRequired" title="EIN is required."
                                            style="color:Red;visibility:hidden;">*</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td align="left" valign="top">
                                          <label for="ctl00_mainContent_Login1_Password"
                                            id="ctl00_mainContent_Login1_PasswordLabel">Password:</label>
                                        </td>
                                        <td valign="top" align="left" width="200">
                                          <input name="password" type="password" id="password" name="password"
                                            style="width:150px;">
                                          <span id="ctl00_mainContent_Login1_PasswordRequired"
                                            title="Password is required." style="color:Red;visibility:hidden;">*</span>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td align="right" colspan="3" valign="top" class="LoginFooter">
                                          <input type="submit" name="ctl00$mainContent$Login1$LoginButton"
                                            value="Log In" id="ctl00_mainContent_Login1_LoginButton"
                                            style="width:75px;">
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

        </div>





      </div>
      <div id="footer">
        <ul>
          <li><b>Version: 2.0.0 </b>:: <a href="default.aspx">SECC Home</a></li>
        </ul>
      </div>
    </div>






  </form>


  <span id="okta-plugin-message-channel-available" style="display: none;"></span>
</body>

</html>
