<div id="paypalMessage" style="text-align: left">

  You are being redirected to Paypal site. After making payment, please <p>
  Wait 10 seconds to be redirected back to SECC, <b>OR </b><p>
  Click on Return to SECC link to return immediately. You will see a payment confirmation message.

  <table>
    <tr>
      <!--
      <td><span mat-dialog-actions>
          <button mat-button [mat-dialog-close]="false">Cancel</button>
        </span>
      </td>
    -->
      <td>
        <span mat-dialog-actions>
          <button mat-button [mat-dialog-close]="false">Cancel</button>
          <button mat-button [mat-dialog-close]="true">Continue</button>
        </span>
      </td>

    </tr>
  </table>
</div>

