import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { Contributionentry } from '../models/contributionentry.model';
import { DonationSummary } from '../models/donationsummary.model';
import { Employee } from '../models/employee.model';
import { SpecialEventWithDetail } from '../models/specialeventwithdetail.model';


@Injectable({
 providedIn: 'root'
})


export class AdminService {


 contributor: any = {};
 contributionEntry : Contributionentry;
 trxId : string =''; // OrderId/ TrxId for order created in contribution page
 donationSummaryList : DonationSummary[];
 employeeEin : string; //Ein of selected person after name lookup

 //Special Events
 selectedSpecialEvent : SpecialEventWithDetail;


  constructor(private http: HttpClient) {
    console.log('New instance of admin svc being created');
    console.log(new Date());
    }

  getDonationSummaryAll() {
    const donationSummaryEndpoint = environment.server_url_admin + 'getdonationsummaryall';
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(donationSummaryEndpoint, httpOptions);
  }


  searchEmployee(employeeID: string) {
    const searchEmployeeEndpoint = environment.server_url_admin + 'searchemployee?employeeID=' + employeeID;
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(searchEmployeeEndpoint, httpOptions);
  }


  public getAllNonProfitsAll()  /* for current campaign year */
  {
      const allNpEndpoint = environment.server_url_admin + 'getnonprofitall' ;
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      return this.http.get(allNpEndpoint, httpOptions);
  }


  searchEmployeeByName(employee: Employee) {


    const searchEmployeeEndpoint = environment.server_url_admin + 'searchemployeebyname';
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const bodyString = JSON.stringify(employee);
    return this.http.post(searchEmployeeEndpoint, bodyString, httpOptions);
  }


  isPayrollEnabledAdmin(contribId: string) {


    const payrollEnabledEndpoint = environment.server_url_admin + 'ispayrollenabledadmin?contribId=' + contribId;
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(payrollEnabledEndpoint, httpOptions);


  }


  public saveContributions(contributionEntry: Contributionentry) {
    const saveContributionsEndpoint = environment.server_url_admin + 'savecontributions';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const bodyString = JSON.stringify(contributionEntry);
    return this.http.post(saveContributionsEndpoint, bodyString, httpOptions);
  }


  getDesignationsNP(trxid: string) {
    const designationsEndpoint = environment.server_url_admin + 'getdesignationsnp?trxid=' + trxid;
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(designationsEndpoint, httpOptions);
  }


  getDonationsByAgency() {
    const donationsByAgencyEndpoint = environment.server_url_admin + 'getdonationsbyagency';
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(donationsByAgencyEndpoint, httpOptions);
  }


  getDonationsByNonProfit() {
    const donationsByNonProfitEndpoint = environment.server_url_admin + 'getdonationsbynonprofit';
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(donationsByNonProfitEndpoint, httpOptions);
  }


  getSpecialEvents() {
    const specialEventsEndpoint = environment.server_url_admin + 'getspecialevents';
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(specialEventsEndpoint, httpOptions);
  }

  getSpecialEventWithDetail(id : string) {
    const specialEventWithDetailEndpoint = environment.server_url_admin + 'getspecialeventwithdetail?id=' + id;
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(specialEventWithDetailEndpoint, httpOptions);
  }

  getSpecialEventReport() {
    const specialEventsReportEndpoint = environment.server_url_admin + 'getspecialeventsreport';
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(specialEventsReportEndpoint, httpOptions);
  }


  getAllAgencies() {
    const agenciesEndpoint = environment.server_url_admin + 'getagencies';
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(agenciesEndpoint, httpOptions);
  }

  getDonationsForDelete(ein : string) {
    const donationForDeleteEndpoint = environment.server_url_admin + 'getdonationsfordelete?ein=' + ein;
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(donationForDeleteEndpoint, httpOptions);
  }

  deleteDonation(trxId : string) {
    const deleteDonationEndpoint = environment.server_url_admin + 'deletedonation?trxId=' + trxId;
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(deleteDonationEndpoint, httpOptions);
  }

  saveSpecialEvent( specialEventWithDetail : SpecialEventWithDetail) {
    const saveSpecialEventEndpoint = environment.server_url_admin + 'savespecialevent';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const bodyString = JSON.stringify(specialEventWithDetail);
    return this.http.post(saveSpecialEventEndpoint, bodyString, httpOptions);
  }

  deleteSpecialEvent(seId : string) {
    const deleteSpecialEventEndpoint = environment.server_url_admin + 'deletespecialevent?seId=' + seId;
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(deleteSpecialEventEndpoint, httpOptions);
  }



}



