import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment } from "../../environments/environment";
import { SearchParams } from '../models/searchparams.model';
import { NonprofitcontributeComponent } from '../donate/nonprofitcontribute/nonprofitcontribute.component';
import { ContributionOrder } from '../models/contributionorder.model';

declare var Spinner : any; // Spinner from spin.js

@Injectable({
    providedIn: 'root'
  })
export class SeccdonateService {

    //Participant Year
    participantYear : number;
    firstDeductionDate : string;

    //for UI & persisting to DB
    public npDonations : Array<NonprofitcontributeComponent>;  //donations saved here on 'continue'
    annualAmount : number; //Amount
    paymentType : string;
    volunteerHours : number;

    //Full name of logged in user
    userFullName : string;
    campaignYear : number;

    /*
     * ContributorId, EmployeeId should NOT be set from client side.
     * Should be set server side by Spring Security
     * TODO: Clean up. Remove from here
    */
    contribId : string ;
    employeeId : string;



    //Donation history
    designationTrxHistory : any= {};  // List of previous donations (List<DesignationTransaction)
    dispReceiptTrx : string =""; // Show trx details for this trx in receipt page (set from history pg)


     //DesignationTransaction
     id : string; //generate uuid on the server side
     createDate : string;
     submitDate : string;
     processDate : string;
     isComplete : boolean;
     total : string; // Amount ?
     processCode : string; //initially null
     payFactor : number;
     orderId : string;

     //Contributor (for contributions UI)
     contributor : any = {}

     //Flag set (=true) only when going to Receipt page from History page.
     // Other times (default) it's false
     isFromHistory : boolean = false;


     constructor(private http: HttpClient) {

     this.npDonations = new Array<NonprofitcontributeComponent>();

    }

    public searchNonProfits(searchParams : SearchParams)
    {
        const searchEndpoint = environment.server_url_contributor + 'searchnonprofit' ;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const bodyString = JSON.stringify(searchParams);
        return this.http.post(searchEndpoint,bodyString, httpOptions);
    }
    getCounties() {

        const countiesEndpoint = environment.server_url_contributor + 'getcounties' ;
        const headers = new Headers({ 'Content-type': 'application/json' });
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.get(countiesEndpoint, httpOptions);
    }

    getServicesProvided() {

        const svcsProvidedEndpoint = environment.server_url_contributor + 'getservicesprovided' ;
        const headers = new Headers({ 'Content-type': 'application/json' });
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.get(svcsProvidedEndpoint, httpOptions);
    }

    //Get contributor email and zip
    ////Note - the ein part is ignored. Will return name of logged in contributor
    // Modify method to drop ein
    getContributor(ein:string) {

        const svcsProvidedEndpoint = environment.server_url_contributor + 'getcontributor?ein=' + ein ;
        const headers = new Headers({ 'Content-type': 'application/json' });
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.get(svcsProvidedEndpoint, httpOptions);
    }

    //Get full name of logged in user
    //this shouldn't be needed. Just call getContributor(/* no arguments */)from the first page
    getUserFullName() {

        const userFullNameEndpoint = environment.server_url_contributor + 'getuserfullname';
        const headers = new Headers({ 'Content-type': 'application/jsonl' });
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.get(userFullNameEndpoint, httpOptions);
    }

    //Get contributor email and zip
    updateContributor(email:string, zip:string) {

        const svcsProvidedEndpoint = environment.server_url_contributor + 'updatecontributor?email=' + email + '&zip=' + zip ;
        const headers = new Headers({ 'Content-type': 'application/json' });
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.get(svcsProvidedEndpoint, httpOptions);
    }

    isPayrollEnabled(contribId : string, totalAmount : number) {

        const payrollEnabledEndpoint = environment.server_url_contributor + 'ispayrollenabled?contribId=' + contribId + '&totalAmount=' + totalAmount ;
        const headers = new Headers({ 'Content-type': 'application/json' });
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.get(payrollEnabledEndpoint, httpOptions);

    }

    submitOrder(payload : ContributionOrder) : Observable<any>{

        const orderSubmissionEndpoint = environment.server_url_contributor + 'submitorder';
        const headers = new Headers({ 'Content-type': 'application/json' });
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const bodyString = JSON.stringify(payload);
        console.log('JSON string=');
        console.log(bodyString);
        return this.http.post(orderSubmissionEndpoint,bodyString,httpOptions);
    }

    getDonationHistory() {

        const donationHistoryEndpoint = environment.server_url_contributor + 'getdonationhistory' ;
        const headers = new Headers({ 'Content-type': 'application/json' });
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.get(donationHistoryEndpoint, httpOptions);
    }

    getConfig(param : string) {

      const configEndpoint = environment.server_url_contributor + 'getconfig?param=' + param ;
      const headers = new Headers({ 'Content-type': 'application/json' });
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      return this.http.get(configEndpoint, httpOptions);
    }


    //////VALIDATION /////////

    isDonationAmountsValid() : boolean {
      for(const donation of this.npDonations) {
        if(!(donation.amount >= 1)) {
          return false;
        }
      }
      return true;
    }


      //////SPINNER//////
  startSpinner(spinarea) {
    var target = document.getElementById(spinarea);
    var spinner = new Spinner(this.opts).spin();
    target.appendChild(spinner.el);
    return spinner;
  }

  stopSpinner(spinner) {
    spinner.stop();
  }

  readonly opts = {
    lines: 13, // The number of lines to draw
    length: 9, // The length of each line
    width: 3, // The line thickness
    radius: 16, // The radius of the inner circle
    scale: 1, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    color: "#888888", // CSS color or array of colors
    fadeColor: "transparent", // CSS color or array of colors
    speed: 1, // Rounds per second
    rotate: 0, // The rotation offset
    animation: "spinner-line-fade-quick", // The CSS animation name for the lines
    direction: 1, // 1: clockwise, -1: counterclockwise
    zIndex: 2e9, // The z-index (defaults to 2000000000)
    className: "spinner", // The CSS class to assign to the spinner
    top: "50%", // Top position relative to parent
    left: "50%", // Left position relative to parent
    shadow: "0 0 1px transparent", // Box-shadow for the lines
    position: "absolute", // Element positioning
  };
}
