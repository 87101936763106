import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { Component, OnInit,Input,Output,EventEmitter,ChangeDetectorRef } from '@angular/core';
import { SeccdonateService } from '../../services/seccdonate.service';


/*
 * Class used to store contributions from a user. Each contribution to an organization corresponds
   to an instance of this class.  Stored in the service object so it's globally accessible
   from across the application (wherever service is injected)

   Class member field names remain faithful to db table NonProfit.
*/

@Component({
  selector: 'app-nonprofitcontribute',
  templateUrl: './nonprofitcontribute.component.html',
  styleUrls: ['./nonprofitcontribute.component.css']
})
export class NonprofitcontributeComponent implements OnInit {


  @Input() nonProfitTitle : string;  // max length 100
  @Input() nonProfitId : string;
  @Input() code : string; //stCode
  @Input() idx : number;

  transactionId : string;
  contributorId : string;  // ein
	batchNumber : string;
	envelopeNumber : string; // max length 7
	participantYear : string;
  amount : number;
  annualAmount : number;
	notifyRecipient : boolean;
	exception : boolean;
	instructions : string; // max length 255
	week : string;
	clickAndGive : boolean;

  /////
  index  : number ; // The position of this instance in the npDonations/ npContributions array;

  @Output("updateTotalInParent")
  callParent: EventEmitter<number> = new EventEmitter();

  constructor(private seccdonateService : SeccdonateService,
              private sessiontimeoutService : SessiontimeoutService) {

  }

  ngOnInit() {

    this.sessiontimeoutService.reset();
    //If in Edit mode (npDonations array already contains donations)
    if(this.seccdonateService.npDonations.length > 0) {
      this.amount = this.seccdonateService.npDonations[this.idx].amount;
    }
  }

  //Fires the 'updateTotalInParent' event in parent template
  calcTotal() {

      this.callParent.emit(this.amount);
  }

}
