
  <div>
    <div style="font-family:Verdana; width:auto;font-size:small;">
      <h3 style="color:#4A598C;">Errors with contributor names / user IDs</h3>
      <p>The Click and Give Service uses employee information based on the <strong>logged-in user of a
          computer</strong>. If the name that is displayed is not yours, do not continue using the service and contact
        your network administrator for assistance.</p>
    </div>
  </div>

  <table>
    <tr>
      <td><span mat-dialog-actions>
          <button mat-button [mat-dialog-close]="false">Close</button>
        </span></td>
    </tr>
  </table>

