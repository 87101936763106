import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table'
import {MatCheckboxModule} from '@angular/material/checkbox'
import {MatButtonModule} from '@angular/material/button'
import {MatSelectModule} from '@angular/material/select'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule} from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { DesignationsComponent } from './donate/designations/designations.component';
import { NonprofitcontributeComponent } from './donate/nonprofitcontribute/nonprofitcontribute.component';
import { ContributionsComponent } from './donate/contributions/contributions.component';
import { PaymentmethodComponent } from './donate/paymentmethod/paymentmethod.component';
import { SubmissionComponent } from './donate/submission/submission.component';
import { ReceiptComponent } from './donate/receipt/receipt.component';
import { WelcomeComponent } from './donate/welcome/welcome.component';
import { HistoryComponent } from './donate/history/history.component';
import { LoginComponent } from './donate/login/login.component';
import { ConfirmmodalComponent } from './donate/confirmmodal/confirmmodal.component';

//Timeout
import { MatIconModule } from '@angular/material/icon';
import { IdletimeoutdialogComponent } from './donate/idletimeoutdialog/idletimeoutdialog.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ThankyouComponent } from './donate/thankyou/thankyou.component';
import { AdminloginComponent } from './donate/admin/adminlogin/adminlogin.component';
import { AdminhomeComponent } from './donate/admin/adminhome/adminhome.component';
import { ContributionentryComponent } from './donate/admin/contributionentry/contributionentry.component';
import { AdminreceiptComponent } from './donate/admin/adminreceipt/adminreceipt.component'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { DonationbyagencyComponent } from './donate/admin/report/donationbyagency/donationbyagency.component';
import { DonationbynonprofitComponent } from './donate/admin/report/donationbynonprofit/donationbynonprofit.component'

//PrimeNG
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { PaginatorModule } from 'primeng/paginator';
import { ButtonModule } from 'primeng/button';
import { EmployeeidlookupComponent } from './donate/admin/employeeidlookup/employeeidlookup.component';
import { ListeventsComponent } from './donate/admin/managespecialevents/listevents/listevents.component';
import { AddeventComponent } from './donate/admin/managespecialevents/addevent/addevent.component';
import { EditeventComponent } from './donate/admin/managespecialevents/editevent/editevent.component';
import { DeletedonationComponent } from './donate/admin/deletedonation/deletedonation.component';
import { SpecialeventreportComponent } from './donate/admin/managespecialevents/report/specialeventreport/specialeventreport.component';
import { NotyouComponent } from './donate/notyou/notyou.component';
import { PaymentcancelComponent } from './donate/paymentcancel/paymentcancel.component';
import { PaypalmesgmodalComponent } from './donate/paypalmesgmodal/paypalmesgmodal.component';


@NgModule({
  declarations: [
    AppComponent,
    DesignationsComponent,
    NonprofitcontributeComponent,
    ContributionsComponent,
    PaymentmethodComponent,
    SubmissionComponent,
    ReceiptComponent,
    WelcomeComponent,
    HistoryComponent,
    LoginComponent,
    ConfirmmodalComponent,
    IdletimeoutdialogComponent,
    ThankyouComponent,
    AdminloginComponent,
    AdminhomeComponent,
    ContributionentryComponent,
    AdminreceiptComponent,
    DonationbyagencyComponent,
    DonationbynonprofitComponent,
    EmployeeidlookupComponent,
    ListeventsComponent,
    AddeventComponent,
    EditeventComponent,
    DeletedonationComponent,
    SpecialeventreportComponent,
    NotyouComponent,
    PaymentcancelComponent,
    PaypalmesgmodalComponent

  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatIconModule,
    MatNativeDateModule,
    MatDatepickerModule,
    CardModule,
    TableModule,
    PaginatorModule,
    ButtonModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
