import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { DonationByNonProfit } from '../../../../models/donationbynonprofit.model';
import { AdminService } from '../../../../services/admin.service';
import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { BannerService } from 'src/app/services/banner.service';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-donationbynonprofit',
  templateUrl: './donationbynonprofit.component.html',
  styleUrls: ['./donationbynonprofit.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DonationbynonprofitComponent implements OnInit {


  donationsSubscription: Subscription = new Subscription();

  donations: DonationByNonProfit[][];
  constructor(public adminSvc: AdminService,
              public bannerSvc : BannerService,
              private sessiontimeoutService : SessiontimeoutService
  ) { }

  //Pagination
  currentPage = 0;
  totalPages: number;
  currentPageData: DonationByNonProfit[][];



  ngOnInit(): void {
    this.sessiontimeoutService.reset();
    this.bannerSvc.getDonationSummaryAll();
    console.log('admin svc ds list');
    console.log(this.adminSvc);
    this.getDonations();
  }

  //Pagination
  onPageChange($event) {
    this.setCurrentPage($event.page);
  }

  //Populate page data for the specified page
  setCurrentPage(currentPage: number) {
    this.currentPageData = this.donations.slice(currentPage * 3, (currentPage + 1) * 3);
  }


  getDonations() {

    this.adminSvc.getDonationsByNonProfit().subscribe(
      response => {

        this.donations = response as DonationByNonProfit[][];
        this.totalPages = Math.ceil(this.donations.length / 3); // Calculate total pages
        this.setCurrentPage(0); // init to first page
      },
      err => {
        console.log(err);
      });

  }

  calcTotal(tables: DonationByNonProfit[]) {
    let amount: Number = 0;
    tables.forEach(x => {
      amount = +amount + +x.amount
    });
    return amount;
  }


  generatePdf() {

    const doc = new jsPDF();

    //Insert Page Header "Donations By Agency"
    this.donations.forEach(nonProfitDonation => {
      let tableData : string[][];
       tableData =  nonProfitDonation.map(item => [item.federationType, item.organization, item.paymentType, '$' + item.amount]);
       tableData.push(['','','TOTAL: ', '$'+(this.calcTotal(nonProfitDonation)).toString()]);

       autoTable(doc, {
        head: [['Federation Type', 'Organization', 'Payment Type', 'Amount']],
        body: tableData,
      });

    });
    doc.save('donationsByNonProfit.pdf');
  }


  generateExcel() {
    let allDonations : DonationByNonProfit[] =
    this.donations.reduce((acc, val) => acc.concat(val), []); //Reduce 2D array to 1D array ('flatten')

    console.log('1D array');
    console.log(allDonations);
    const worksheet = XLSX.utils.json_to_sheet(allDonations);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    // Generate Excel buffer
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Save the file
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const blobName = 'donationsByNonProfit.xlsx';
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = blobName;
    link.click();
  }

  ngOnDestroy() {

    if (this.donationsSubscription != undefined) this.donationsSubscription.unsubscribe();
  }


}
