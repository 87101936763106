import { Designation } from "./designation.model";

/*
 Class encapuslating the entire 'order' (all contributions) as submitted
 to the backend
*/

export class ContributionOrder {

    id : string;
    createDate : string;
    isComplete : boolean;
    isDeleted : boolean;
    nameOnCard : string;
    orderID : string;
    paymentType : string;
    processCode : string;
    processDate : string;
    submitDate : string;
    total : number;
    contributorID : string;
    payFactor : string;

    annualAmount : string; //Amount (total)
    volunteerHours : string;
    contribId : string = ''; //Hardcoded for now. TODO change after Login integration
    employeeId : string;

    designations : Array<Designation> ;

    constructor() {

        this.designations = new Array<Designation>();
    }

}
