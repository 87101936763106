import { Designation } from "./designation.model";

/*
Container for contribution as submitted by ADMIN
*/

export class Contributionentry {

  contributor : any = {}
  address : string;
  city : string;
  state : string;
  zip : string;
  campaignWeek : string;
  volHours : string;
  paymentType : string;
  total : string;

  pledgeDate : Date = new Date();
  designations  = new Array<Designation>(5);


  constructor() {
    this.total = "0.00";
    for(let i=0;i<this.designations.length;i++) {
      this.designations[i] = new Designation();
    }
  }


}
