export class Designation {

	  id : string;//number;
	  amount : string;//number;
	  batchNumber :string;// number;
	  clickAndGive : string;
	  contributorID : string;// number;
	  envelopeNumber : string;
	  exception : string;
	  instructions : string;
	  nonProfitID : string;//number;
    nonProfitCode : string; //NEW
    nonProfitTitle : string;
	  notifyRecipient : string;
	  participantYear : string;//number;
	  transactionID : string;
	  week : string;//number;

    constructor() {
      this.amount = "0";
    }

}
