<div id="ctl00_mainContent_es1_searchPanel"
  style="display: inline; position: absolute; width: 285px; top: 50%; left: 50%; margin-top: -40px; margin-left: -142px;">
  <form Action="">
    <div style="border: solid #666 1px; background-color: #4A598C; color: #fff; width: 285px;">
      <p style="margin: 0px; padding: 6px;">
        <strong>Employee ID Lookup</strong>
      </p>
    </div>
    <div style="border: solid #666 1px; background-color: #D3DCE8; width: 285px;">
      <div style="float: left; padding: 8px;">
        First Name<br>
        <input name="ctl00$mainContent$es1$PayrolldbFirstName" type="text" id="ctl00_mainContent_es1_PayrolldbFirstName"
          [(ngModel)]="employee.firstName" size="7">
      </div>
      <div style="float: left; padding: 8px;">
        Last Name<br>
        <input name="ctl00$mainContent$es1$PayrolldbLastName" type="text" id="ctl00_mainContent_es1_PayrolldbLastName"
          [(ngModel)]="employee.lastName" size="7">&nbsp;<input type="button" id="PayrolldbSearchButton" value="Search"
          (click)="searchEmployee()">
      </div>
      <div style="clear: left; padding: 8px;">


        <select name="ctl00$mainContent$es1$PayrolldbEmplIdList" id="empListBox" [(ngModel)]="adminSvc.employeeEin"
          (dblclick)="onDoubleClick($event.target.value)" size="2">

          <option value="enter a first and last name to search" id="listboxplaceholder">enter a first and last name to search</option>
          <ng-container *ngFor="let item of searchResult" >
            <option *ngIf="isShowSearchResult" [value]="item.ein">{{item.lastName + ', ' + item.firstName}}</option>
          </ng-container>
        </select>
      </div>
      <div style="padding: 8px;">
        <input type="button" id="emplIdButton" value="Select" (click)="onSelect()">&nbsp;&nbsp;&nbsp;
        <input type="button" id="closeButton" value="Close" (click)="close()">
      </div>
    </div>
  </form>
</div>
