import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { DonationSummary } from '../models/donationsummary.model';


/*
 * For some (as of this writing) unknown reason Angular
   is injecting new instances of the AdminService into components
   despite having providedIn: 'root' in the @Injectable decorater.
   Because of it the service is unable to store global values and
   the banner values need to be recalculated inside every component.
   Not ideal but it's the workaround for the time being.

   This service is only responsible for calculating banner values.
   Written as a separate service to make it easier to remove when
   a solution to the singleton issue is found

*/

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(private http: HttpClient) { }

   //Donation Summary section
   private donationSummarySubscription : Subscription = new Subscription();
   public donationSummaryList : DonationSummary[] = new Array<DonationSummary>();



  getDonationSummaryAll() {

    this.donationSummarySubscription = this.getDonationSummaryAllSvc().subscribe(

      result => {
        this.donationSummaryList = result as Array<DonationSummary>;
      },
      err => {
        console.log(err);
      }
  );
}

private  getDonationSummaryAllSvc() {
    const donationSummaryEndpoint = environment.server_url_admin + 'getdonationsummaryall';
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(donationSummaryEndpoint, httpOptions);
  }
}
