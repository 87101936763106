import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Employee } from '../../../models/employee.model';
import { AdminService } from 'src/app/services/admin.service';
import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';

@Component({
  selector: 'app-employeeidlookup',
  templateUrl: './employeeidlookup.component.html',
  styleUrls: ['./employeeidlookup.component.css']
})
export class EmployeeidlookupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EmployeeidlookupComponent>,
              public adminSvc : AdminService,
              private sessiontimeoutService : SessiontimeoutService
  )
  {}

  searchSubscription : Subscription = new Subscription();

  employee : Employee = new Employee();
  searchResult : Employee[];
  isShowSearchResult : boolean = false;

  ngOnInit(): void {
    this.isShowSearchResult = false;
    this.sessiontimeoutService.reset();
  }

  searchEmployee() {

    this.searchSubscription = this.adminSvc.searchEmployeeByName(this.employee).subscribe(response => {
                                  console.log(response);
                                  this.searchResult = response as Array<Employee>;
                                  this.formatListBox(this.searchResult);
                                  this.isShowSearchResult = true;
                                },
                                err => {
                                  console.log(err);
                                }
                              );
  }


  close(): void {
    this.dialogRef.close();
  }

  formatListBox(empList : Employee[]) {

    var listbox = document.getElementById('empListBox') as HTMLSelectElement;
    if(empList.length < 5) {
      listbox.size = empList.length;
    }
    if(empList.length < 2) {
      listbox.size = 2;
    }
    if(empList.length >= 5) {
      listbox.size = 5;
    }
    if(empList.length > 0 ) {
      document.getElementById('listboxplaceholder').style.display = 'none';
    } else {
      document.getElementById('listboxplaceholder').style.display = 'inline';
    }
  }

  onDoubleClick(selectedValue : any) {
    this.adminSvc.employeeEin = selectedValue;
    this.close();
  }

  onSelect() {
    this.close();
  }

  ngOnDestroy() {

    if(this.searchSubscription != undefined) this.searchSubscription.unsubscribe();
  }

}
