<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  <title>
    SECC Online Donations
  </title>
</head>

<body>
  <div id="wrapper">
    <div id="header">
      <h1><a href="default.aspx">Arizona State Employee Charitable Campaign</a></h1>
      <img id="ctl00_imgHeader" src="assets/img/secc.png" alt="Arizona State Employee Charitable Campaign"
        style="border-width:0px;">
    </div>
    <div id="nav">

      <ul>
        <li>
          <a id="ctl00_LoginView1_LoginStatus1" title="Logout"  href="contributor/auth/dologout">LOGOUT</a>
        </li>
        <!--<li><a href="feedback.aspx" title="Feedback">FEEDBACK</a></li>//-->
        <li><a href="Download/Instructions.pdf" title="Instructions" target="_blank">INSTRUCTIONS</a></li>
        <li><a  href="contributor/welcome"title="Home">HOME</a></li>
      </ul>

    </div>
  </div>
  <div><p>&nbsp;</p></div>
  <div><p>&nbsp;</p></div>
  <div><p>&nbsp;</p></div>
  <div><p>&nbsp;</p></div>

  <div>

   <span> <font size='4' color="red">Thank you for your donation </font></span>
  </div>
</body>
