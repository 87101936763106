<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
  <meta name="description" content="">
  <meta name="author" content="">
  <link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-beta/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-/Y6pD6FV/Vv2HJnA6t+vslU6fwYXjCFtcEpHbNJ0lyAFsXTsjBbfaDjzALeQsN6M" crossorigin="anonymous">
  <link href="https://getbootstrap.com/docs/4.0/examples/signin/signin.css" rel="stylesheet" crossorigin="anonymous"/>
  <title>SECC Online Donations</title>
</head>
<body>
    <div id="wrapper">
      <div id="header">
        <h1>
          <a href="default.aspx">Arizona State Employee Charitable Campaign</a>
        </h1>
        <img
          id="ctl00_imgHeader"
          src="assets/img/secc.png"
          alt="Arizona State Employee Charitable Campaign"
          style="border-width: 0px"
        />
      </div>
      <div id="nav"></div>
      <!-- INSERT LOGIN BOX HERE-->

      <header>
        <h2>SECC Donate</h2>
        <h5>Login</h5>
        <hr/>
      </header>

        <article class="artcle">
         <div class="container">

          <div id="spinarea-secc"></div>
          <form ngNoForm class="form-signin" method="post" action="contributor/auth/dologin" (submit)="showSpinner()">
            <!--<h2 class="form-signin-heading">Service Desk (Test) Login </h2>-->
            <p>
              <label for="username" class="sr-only">Employee EIN</label> EIN
              <input type="text" id="username" name="username" class="form-control" placeholder="No leading zeroes" required autofocus>
            </p>
            <p>
              <label for="Y.E.S. Password" class="sr-only">Y.E.S. Password</label> Y.E.S. Password
              <input type="password" id="password" name="password" class="form-control" placeholder="Y.E.S. Password" required>
            </p>
            <div *ngIf="isLoginError">
              <font color="red" size="2">Your login attempt failed. Please try again.</font>
            </div>
            <button class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
          </form>
          </div>
          </article>

        <footer>
          <hr/>
          <div class="copyright">&copy;2023 - <strong>State of Arizona</strong></div>

          <hr/>
        </footer>


      <!--END INSERT-->
      <div id="footer">
        <table width="100%">
          <tbody>
            <tr>
              <td width="47%">
                <a
                  href="mailto:Linda.Stiles@azdoa.gov"
                  id="ctl00_aDirector"
                  target="_blank"
                  >Linda.Stiles@azdoa.gov</a
                >
              </td>
              <td>
                <a href="default.aspx">SECC Home</a>
              </td>
              <td style="text-align: right; font-weight: bold">
                Version: 2.0.0
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>



  <div
    class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-draggable ui-resizable"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ui-dialog-title-ctl00_Timeout1"
    style="
      display: none;
      position: absolute;
      overflow: hidden;
      z-index: 1000;
      outline: 0px;
    "
  >
    <div
      class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix"
      unselectable="on"
    >
      <span
        class="ui-dialog-title"
        id="ui-dialog-title-ctl00_Timeout1"
        unselectable="on"
        >Session Expiring</span
      ><a
        href="#"
        class="ui-dialog-titlebar-close ui-corner-all"
        role="button"
        unselectable="on"
        ><span class="ui-icon ui-icon-closethick" unselectable="on"
          >close</span
        ></a
      >
    </div>
    <span
      id="ctl00_Timeout1"
      style=""
      class="ui-dialog-content ui-widget-content"
    >
      <p>
        <span
          class="ui-icon ui-icon-alert"
          style="float: left; margin: 1px 10px 20px 0"
        >
        </span
        >Your session will expire in.
      </p>
      <span id="tscCounter"></span>&nbsp;seconds
      <p>Click <b>OK</b> to continue your session.</p>
    </span>
    <div class="ui-resizable-handle ui-resizable-n" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-e" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-s" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-w" unselectable="on"></div>
    <div
      class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se ui-icon-grip-diagonal-se"
      unselectable="on"
      style="z-index: 1001"
    ></div>
    <div
      class="ui-resizable-handle ui-resizable-sw"
      unselectable="on"
      style="z-index: 1002"
    ></div>
    <div
      class="ui-resizable-handle ui-resizable-ne"
      unselectable="on"
      style="z-index: 1003"
    ></div>
    <div
      class="ui-resizable-handle ui-resizable-nw"
      unselectable="on"
      style="z-index: 1004"
    ></div>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button type="button" class="ui-state-default ui-corner-all">Ok</button>
    </div>
  </div>
</body>
