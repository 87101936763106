<head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  <title>
    SECC Online Donations
  </title>
</head>

<body>


  <form name="aspnetForm" method="post"  id="aspnetForm">


    <div>

    </div>


    <div>
      <span id="dirtyForm" display="none"></span>
      <span id="ctl00_Label1"></span>

    </div>
    <div id="wrapper">
      <div id="header">
        <h1><a href="default.aspx">Arizona State Employee Charitable Campaign</a></h1>
        <img id="ctl00_imgHeader" src="assets/img/secc.png" alt="Arizona State Employee Charitable Campaign"
          style="border-width:0px;">
      </div>
      <div id="nav">

        <ul>
          <li>
            <a id="ctl00_LoginView1_LoginStatus1" title="Logout"
              href="contributor/auth/dologout">LOGOUT</a>
          </li>
          <!--<li><a href="feedback.aspx" title="Feedback">FEEDBACK</a></li>//-->
          <li><a href="assets/doc/Instructions.pdf" title="Instructions" target="_blank">INSTRUCTIONS</a></li>
          <li><a  href="contributor/welcome"title="Home">HOME</a></li>

        </ul>

      </div>
      <div id="container">

        <div id="homewrap">
          <h2 style="text-align: center">Welcome to the SECC Click and Give Site!</h2>

          <p style="text-align: left">
            The Click N Give System allows you to electronically select the charities to whom you'd like to donate.
            You can choose to donate on the Click N Give System via Credit or Debit Card,
            or through payroll deductions.</p>
          <p style="text-align: left">Thank you for supporting the 2025 SECC Campaign!</p>

        </div>
        <div>
          <ul>
            <li style="padding: 5px;"><strong>Click <a href='contributor/designations'>here</a>
                to make your donation today!</strong></li>

            <li style="padding: 5px;"><strong>Click <a href='contributor/history'>here</a> to
                view your previous donations.</strong></li>

            <!--<li style="padding: 5px;"><strong>Click here for &quot;How to Make a Donation&quot; video.</strong></li>-->
          </ul>
        </div>

      </div>
      <div id="footer">
        <table width="100%">
          <tbody>
            <tr>
              <td width="47%"><a href="mailto:Linda.Stiles@azdoa.gov" id="ctl00_aDirector"
                  target="_blank">Linda.Stiles@azdoa.gov</a>
              </td>
              <td>
                <a href="default.aspx">SECC Home</a>
              </td>
              <td style="text-align: right; font-weight: bold;">
                Version: 2.0.0
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


    <script type="text/javascript">
      //<![CDATA[
      (function () { var fn = function () { $get("ctl00_ScriptManager1_HiddenField").value = ''; Sys.Application.remove_init(fn); }; Sys.Application.add_init(fn); })();
      WebForm_InitCallback(); Sys.Application.add_init(function () {
        $create(TSC.Timeout.Timeout, { "aboutToTimeoutMinutes": 19, "clientId": "ctl00_Timeout1", "countDownSpanID": "tscCounter", "dirtyFormSpanID": "dirtyForm", "displayButton": false, "resetSessionOnAsyncPostback": true, "timeoutMinutes": 20, "timeoutURL": "Login.aspx" }, null, null, $get("ctl00_Timeout1"));
      });
//]]>
    </script>
  </form>


  <div class="ui-dialog ui-widget ui-widget-content ui-corner-all  ui-draggable ui-resizable" tabindex="-1"
    role="dialog" aria-labelledby="ui-dialog-title-ctl00_Timeout1"
    style="display: none; position: absolute; overflow: hidden; z-index: 1000; outline: 0px;">
    <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix" unselectable="on"><span
        class="ui-dialog-title" id="ui-dialog-title-ctl00_Timeout1" unselectable="on">Session Expiring</span><a href="#"
        class="ui-dialog-titlebar-close ui-corner-all" role="button" unselectable="on"><span
          class="ui-icon ui-icon-closethick" unselectable="on">close</span></a></div><span id="ctl00_Timeout1" style=""
      class="ui-dialog-content ui-widget-content">
      <p>
        <span class="ui-icon ui-icon-alert" style="float: left; margin: 1px 10px 20px 0;">
        </span>Your session will expire in.
      </p>
      <span id="tscCounter"></span>&nbsp;seconds
      <p>
        Click <b>OK</b> to continue your session.</p>
    </span>
    <div class="ui-resizable-handle ui-resizable-n" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-e" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-s" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-w" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se ui-icon-grip-diagonal-se"
      unselectable="on" style="z-index: 1001;"></div>
    <div class="ui-resizable-handle ui-resizable-sw" unselectable="on" style="z-index: 1002;"></div>
    <div class="ui-resizable-handle ui-resizable-ne" unselectable="on" style="z-index: 1003;"></div>
    <div class="ui-resizable-handle ui-resizable-nw" unselectable="on" style="z-index: 1004;"></div>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix"><button type="button"
        class="ui-state-default ui-corner-all">Ok</button></div>
  </div>
</body>
