import { SeccdonateService } from 'src/app/services/seccdonate.service';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-confirmmodal',
  templateUrl: './confirmmodal.component.html',
  styleUrls: ['./confirmmodal.component.css']
})
export class ConfirmmodalComponent implements OnInit {


  @Input()
  payFactor : number;  // 1 = annual; 26 = pay period

  @Input()
  annualAmount : number;

  constructor(public dialogRef: MatDialogRef<ConfirmmodalComponent> ,
              public seccdonateService : SeccdonateService
  ) { }

  ngOnInit(): void {

  }



}
